import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './index.scss';
import { bool, object, string } from 'prop-types';
import { pushToGoogleAnalytics } from '../../utils/google-analytics';

// TODO refactor images here
const AccordionSection = (props) => {
  const { children, title, expanded = false, accordionDetailsProps = {}, accordionProps = {}, className = '', ...other } = props;

  const onChange = (event, expanded) => {
    const payload = {
      event: expanded ? 'accordionExpand' : 'accordionCollapse',
      text: title
    };

    pushToGoogleAnalytics(payload);
  };

  return (
    <Box className={`accordion_section ${className}`}  {...other}>
      <Accordion
        defaultExpanded={expanded}
        onChange={onChange}
        {...accordionProps}
      >
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon sx={{
            fontSize: {
              xs: '1.5rem',
              md: '2rem',
            }
          }} />}
          aria-controls="panel1bh-content"
          sx={{ minHeight: '50px', py: 2, mt: '0 !important' }}
        >
          <Typography className='recipe_accordion_title' variant='h4'>
            { title }
          </Typography>
          <Divider component={'div'} className='recipe_accordion_divider' />
        </AccordionSummary>
        <AccordionDetails
          sx={{}}
          {...accordionDetailsProps}
        >
          <div>
            { children }
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

AccordionSection.propTypes = {
  title: string.isRequired,
  className: string,
  accordionProps: object,
  accordionDetailsProps: object,
  expanded: bool,
}

export default AccordionSection