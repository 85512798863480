import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { useTranslation } from 'react-i18next';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import PropTypes from 'prop-types';

import {
  AccessTime,
  ArrowDropDown,
  ArrowDropUp,
  Close,
  FactCheck,
  ShoppingBasket,
} from '@mui/icons-material';

import {
	Box,
	Checkbox,
  Collapse,
	FormControlLabel,
	FormGroup,
	Radio,
	RadioGroup,
	Stack,
  Tab,
  Tabs,
	Typography,
} from "@mui/material";

import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ my: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `filter-options-tab-${index}`,
    'aria-controls': `filter-options-tabpanel-${index}`,
    'data-index': index,
  };
}

import notify from '../Notification/helper';

import API from '../../api';

import RetailerContext from '../../context';

import { getTranslation, translateDifficulty } from '../../utils/translate';

import './index.scss';
import { hexToRgb, isLight } from '../../utils/colour';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Filters = ({ onUpdateFilters, onUpdateTabIndex, onClearFilters }) => {
  const retailerContext = useContext(RetailerContext);
  const language = useSelector(state => state.language);

  const { t } = useTranslation();
  const query = useQuery();
  const filterParams = JSON.parse(query.get('filter') || JSON.stringify({}));

  const [value, setValue] = useState(JSON.parse(query.get('tabIndex') || JSON.stringify(0)));

  const [listCategory, setListCategory] = useState([]);

  const [filters, setFilters] = useState(filterParams);

  // filter selected flags
  const [isTimeFilterOn, setIsTimeFilterOn] = useState(!!filters.prepTime || !!filters.cookTime || !!filters.chillTime || !!filters.freezingTime || !!filters.totalTime);
  const [isOptionsFilterOn, setIsOptionsFilterOn] = useState(filters.difficulty > -1 || !!filters.categoryCheckedState?.filter(item => item).length || !!filters.cookingMethodCheckedState?.filter(item => item).length);
  const [isIngredientsFilterOn, setIsIngredientsFilterOn] = useState(!!filters.ingredients || !!filters.recipeYield);

  const [isShowingFilterPanel, setIsShowingFilterPanel] = useState(isTimeFilterOn || isOptionsFilterOn || isIngredientsFilterOn);

  const BEGINNER = 1;
  const INTERMEDIATE = 3;
  const ADVANCED = 5;

  const difficultyMarks = [
    {
      value: BEGINNER,
      label: t('beginner'),
    },
    {
      value: INTERMEDIATE,
      label: t('intermediate'),
    },
    {
      value: ADVANCED,
      label: t('advanced'),
    },
  ];

  const PrettoSlider = styled(Slider)({
    color: isLight(hexToRgb(retailerContext.retailer.brand_color)) ? '#000' : retailerContext.retailer.brand_color,
    height: 2,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 18,
      width: 18,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: retailerContext.retailer.brand_color,
      color: isLight(hexToRgb(retailerContext.retailer.brand_color)) ? '#000' : '#fff',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  function LinkTab(props) {
    return (
      <Box className="tab-container">
        { JSON.parse(props['filter-applied']) ? (<Box className="filter_active-indicator"></Box>) : '' }
        <Tab
          component="a"
          sx={{ minWidth: '0' }}
          onClick={() => { handleLinkTabClick(props) }}
          {...props}
        />
      </Box>
    );
  }

  function handleLinkTabClick(props) {
    if (JSON.parse(props['data-index']) === value) {
      setIsShowingFilterPanel(!isShowingFilterPanel);
    }
  }

  const updateFilters = (keyValuePair) => {
    const newFilters = {
      ...filters,
      ...keyValuePair,
    };

    setFilters(newFilters);

    if (onUpdateFilters) {
      onUpdateFilters(newFilters);
    }

    if (onUpdateTabIndex) {
      onUpdateTabIndex(value);
    }
  }

  useEffect(async () => {
		try {
			const categoriesList = await API.getListCategory({
        filter: {
          '_and': ([
            {
              status: {
                '_eq': 'published'
              }
            }
          ])
        }
      });

			setListCategory(categoriesList);

      if (!filters.categoryCheckedState?.length) {
			  (updateFilters({ categoryCheckedState: new Array(categoriesList.length).fill(null) }));
      }
		} catch (error) {
			error.map((err) => notify("error", err.message));
		} finally {
    }

	}, []);

  const clearAllFilters = () => {
    (
      updateFilters({
        prepTime: 0,
        cookTime: 0,
        chillTime: 0,
        freezingTime: 0,
        totalTime: 0,
        difficulty: -1,
        recipeYield: 0,
        categoryCheckedState: new Array(listCategory.length).fill(null),
        ingredients: 0,
      })
    );

    setIsShowingFilterPanel(false);

    if (onClearFilters) {
      onClearFilters();
    }
  };

  const handleCategoryCheck = (position) => {
    const updatedCategoryCheckedState = filters.categoryCheckedState?.map((item, index) => {
      if (index === position) {
        return item ? null : listCategory[position].id
      } else {
        return item
      }
    });

    (updateFilters({ categoryCheckedState: updatedCategoryCheckedState }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsShowingFilterPanel(false);

    if (onUpdateTabIndex) {
      onUpdateTabIndex(newValue);
    }

    setTimeout(() => {
      setIsShowingFilterPanel(true);
    }, 500)
  };

  useEffect(() => {
      setIsTimeFilterOn(!!filters?.prepTime || !!filters?.cookTime || !!filters?.chillTime || !!filters?.freezingTime || !!filters?.totalTime);
      setIsOptionsFilterOn((filters?.difficulty > -1) || !!filters?.categoryCheckedState?.filter(item => item).length || !!filters?.cookingMethodCheckedState?.filter(item => item)?.length);
      setIsIngredientsFilterOn(!!filters?.ingredients || !!filters?.recipeYield);
  }, [filters]);

  return (
    <>
      <Box
        className="filter filter_tabs"
        style={{ my: 0 }}
      >
        <Box
          sx={{ position: 'relative', height: '56px' }}
        >
          <Box
            sx={{ position: 'absolute', opacity: '0.53', height: '46px', left: 0, right: 0, margin: '6px', mb: 2, marginTop: '4px', background: 'rgba(255,255,255,0.73)' }}
          >
          </Box>

          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ position: 'absolute', height: '46px', margin: '6px', left: '0', right: 0, mb: 2, marginTop: '4px', background: 'transparent' }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="icon position tabs example"
            >
              <LinkTab
                icon={(
                  <AccessTime
                    sx={{ color: isLight(hexToRgb(retailerContext.retailer.brand_color)) ? '#000' : retailerContext.retailer.brand_color }}
                  />
                )}
                filter-applied={`${isTimeFilterOn}`}
                {...a11yProps(0)}
              />
              <LinkTab
                icon={(
                  <FactCheck
                    sx={{ color: isLight(hexToRgb(retailerContext.retailer.brand_color)) ? '#000' : retailerContext.retailer.brand_color }}
                  />
                )}
                filter-applied={`${isOptionsFilterOn}`}
                {...a11yProps(1)}
              />
              <LinkTab
                icon={(
                  <ShoppingBasket
                    sx={{ color: isLight(hexToRgb(retailerContext.retailer.brand_color)) ? '#000' : retailerContext.retailer.brand_color }}
                  />
                )}
                filter-applied={`${isIngredientsFilterOn}`}
                {...a11yProps(2)}
              />
            </Tabs>

            <Stack
              className="tab-container"
              direction={'row'}
              sx={{ ml: 'auto' }}
            >
              <Tabs
                value={value}
              >
                <Tab />
                <Tab
                  component="a"
                  sx={{ minWidth: '0', px: 0 }}
                  icon={
                    (isTimeFilterOn || isOptionsFilterOn || isIngredientsFilterOn ) ?
                    <Close sx={{ fontSize: '14px' }} style={{ color: retailerContext.retailer.brand_color }} /> :
                    <></>
                  }
                  onClick={clearAllFilters}
                />
                <Tab
                  component="a"
                  sx={{ minWidth: '0' }}
                  icon={
                    isShowingFilterPanel ?
                    <ArrowDropUp style={{ color: retailerContext.retailer.brand_color }} /> :
                    <ArrowDropDown style={{ color: retailerContext.retailer.brand_color }} />
                  }
                  onClick={() => { 
                    setIsShowingFilterPanel(!isShowingFilterPanel);
                  }}
                />
              </Tabs>
            </Stack>
          </Stack>
        </Box>

        <Box style={{ }}>
          <Collapse in={isShowingFilterPanel}>
            <>
              <Box px={2} mx={1}>
                <TabPanel value={value} index={0}>
                  <Stack
                    sx={{ mb: 2 }}
                  >
                      <Stack
                        direction="row"
                        alignItems={'center'}
                      >
                        <Typography className="filter_label">{ t('prepTime') }</Typography>
                        <Typography className="units">{t('upTo')} {filters.prepTime || 0} { t('mins') }</Typography>
                      </Stack>
                      <PrettoSlider
                        label="Outlined"
                        aria-label="Prep Time"
                        value={filters.prepTime}
                        onChange={(event, prepTime) => { (updateFilters({ prepTime })) }}
                        valueLabelDisplay="auto"
                        step={5}
                        min={0}
                        max={720}
                      />
                    </Stack>

                    <Stack
                      sx={{ mb: 2 }}
                    >
                      <Stack
                        direction="row"
                        alignItems={'center'}
                      >
                        <Typography className="filter_label">{ t('cookTime') }</Typography>
                        <Typography className="units">{t('upTo')} {filters.cookTime || 0} { t('mins') }</Typography>
                      </Stack>
                      <PrettoSlider
                        label="Outlined"
                        aria-label="Cooking Time"
                        value={filters.cookTime}
                        onChange={(event, cookTime) => { (updateFilters({ cookTime })) }}
                        valueLabelDisplay="auto"
                        step={5}
                        min={0}
                        max={720}
                      />
                    </Stack>

                    <Stack
                      sx={{ mb: 2 }}
                    >
                      <Stack
                        direction="row"
                        alignItems={'center'}
                      >
                        <Typography className="filter_label">{ t('chillTime') }</Typography>
                        <Typography className="units">{t('upTo')} {filters.chillTime || 0} { t('mins') }</Typography>
                      </Stack>
                      <PrettoSlider
                        label="Outlined"
                        aria-label="Chill Time"
                        value={filters.chillTime}
                        onChange={(event, chillTime) => { (updateFilters({ chillTime })) }}
                        valueLabelDisplay="auto"
                        step={5}
                        min={0}
                        max={720}
                      />
                    </Stack>

                    <Stack
                      sx={{ mb: 2 }}
                    >
                      <Stack
                        direction="row"
                        alignItems={'center'}
                      >
                        <Typography className="filter_label">{ t('freezingTime') }</Typography>
                      <Typography className="units">{t('upTo')} {filters.freezingTime || 0} { t('mins') }</Typography>
                      </Stack>
                      <PrettoSlider
                        label="Outlined"
                        aria-label="Freezing Time"
                        value={filters.freezingTime}
                        onChange={(event, freezingTime) => { (updateFilters({ freezingTime })) }}
                        valueLabelDisplay="auto"
                        step={5}
                        min={0}
                        max={720}
                      />
                    </Stack>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Stack sx={{ }}>
                    <Stack
                      direction="row"
                      alignItems={'center'}
                    >
                      <Typography className="filter_label">{t('difficulty')}</Typography>
                    </Stack>

                    <RadioGroup>
                      {
                        difficultyMarks
                          .map((difficulty, index) => (
                            <FormControlLabel
                              // category={category}
                              key={difficulty.value}
                              checked={filters.difficulty === difficulty.value}
                              onChange={() => { (updateFilters({ difficulty: difficulty.value })) }}
                              control={(
                                <Radio
                                  sx={{
                                    color: retailerContext.retailer.brand_color,
                                  }}
                                />
                              )}
                              label={(
                                <Typography>
                                  {difficulty.label}
                                </Typography>
                              )}
                            />
                          ))
                      }
                    </RadioGroup>
                  </Stack>

                    <Box sx={{ mt: 3 }}>
                      <Accordion disableGutters={true} square={true} sx={{ px: 0, background: 'transparent', boxShadow: 'none' }}>
                        <AccordionSummary
                          expandIcon={<ArrowDownwardIcon />}
                          aria-controls="panel3bh-content"
                          id="panel3bh-header"
                        >
                          <Typography sx={{ fontWeight: 200, textTransform: 'uppercase' }}>{t('categories')} ({filters.categoryCheckedState?.filter(item => item)?.length || 0})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack sx={{ my: 2, px: 1 }}>
                            <FormGroup>
                              {
                                listCategory
                                  .filter(category => getTranslation(category, { key: 'languages_code', code: language.code })?.category)
                                  .map((category, categoryIndex) => (
                                  <FormControlLabel
                                    category={category}
                                    key={categoryIndex}
                                    checked={filters.categoryCheckedState ? !!filters.categoryCheckedState[categoryIndex] : false}
                                    onChange={() => { handleCategoryCheck(categoryIndex) }}
                                    control={<Checkbox />}
                                    label={<Typography>{ getTranslation(category, { key: 'languages_code', code: language.code }).category }</Typography>}
                                  />
                                ))
                              }
                            </FormGroup>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                </TabPanel>
                
                <TabPanel value={value} index={2}>
                  <Stack>
                    <Stack
                      direction="row"
                      alignItems={'center'}
                    >
                      <Typography className="filter_label">{ t('portions') }</Typography>
                      <Typography className="units">{t('upTo')} {filters.recipeYield || 0} { t('portions') }</Typography>
                    </Stack>
                    <PrettoSlider
                      label="Outlined"
                      aria-label="Yield"
                      value={filters.recipeYield}
                      onChange={(event, recipeYield) => { (updateFilters({ recipeYield })) }}
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={12}
                    />
                  </Stack>

                  <Stack sx={{ my: 2 }} >
                    <Stack
                      direction="row"
                      alignItems={'center'}
                    >
                      <Typography className="filter_label">{t('ingredients')}</Typography>
                      <Typography className="units">{t('upTo')} {filters.ingredients || 0} {t('ingredients') }</Typography>
                    </Stack>
                    <PrettoSlider
                      label="Outlined"
                      aria-label="Yield"
                      value={filters.ingredients}
                      onChange={(event, ingredients) => { (updateFilters({ ingredients })) }}
                      valueLabelDisplay="auto"
                      step={1}
                      min={0}
                      max={20}
                    />
                  </Stack>
                </TabPanel>
              </Box>
            </>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

Filters.defaultProps = {
  setFilters: () => {}
}

export default Filters;
