import React from 'react';

import LazyLoad from 'react-lazyload';

import {
    Grid
} from "@mui/material";

import './styles.module.scss';
import RecipeTile from '../../RecipeTile';

// TODO refactor this component
const TileGrid = ({ tiles }) => {
    return (
        <Grid container
            spacing={{
                xs: 0,
            }}
        >
            {
                tiles?.map((tile) => (
                    <Grid
                        key={tile.id} item 
                        xs={12}
                        md={6}
                        lg={6}
                    >
                        <LazyLoad height={330} once={true}>
                            <RecipeTile
                                key={tile.id}
                                recipe={tile}
                            />
                        </LazyLoad>
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default TileGrid;
