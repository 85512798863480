import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';
import ContestArt from '../../../shared/img/ContestArt.png';

import Wysiwyg from "../../Wysiwyg";

import styles from './styles.module.scss';
import { DialogContestTitle } from "../DialogContestTitle";
import { DialogContestFooter } from "../DialogContestFooter";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestInfoDialogBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { retailer } = useContext(RetailerContext);

    const handleClose = (componentToOpen = null) => {
        onClose(componentToOpen);
    };

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={() => handleClose('promotion')}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        mt: 2,
                        lineHeight: '1.25rem'
                    }}
                >
                    <Wysiwyg>
                        {t('enterContestInfoSubtitle')}
                    </Wysiwyg>
                </Typography>

                <Typography
                    variant={'h2'}
                    component={'div'}
                    sx={{
                        color: '#B92534',
                        fontWeight: 'bold',
                        fontSize: '1.26rem',
                        textAlign: 'center',
                        mb: 2,
                        mt: "0.5rem",
                        lineHeight: '1rem'
                    }}
                >
                    {t('enterContestDescription')}
                </Typography>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                    }}
                >
                    <img
                        src={ContestArt}
                        alt={'Contest Art'}
                        style={{
                            height: '150px',
                            width: '100%',
                            objectFit: 'contain',
                            objectPosition: 'bottom',
                        }}
                    />
                </Box>

                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: '0',
                                backgroundColor: '#008F01',
                                fontFamily: retailer.button_font,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 1,
                            }}
                            onClick={() => handleClose('contest-form') }
                        >
                            {t('enterContest')}
                        </Button>

                        <DialogContestFooter
                            rules={true}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
export const DialogContestInfo = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box className='no-print'>
            <ContestInfoDialogBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
};