import { Link, useLocation } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';

import Wysiwyg from '../Wysiwyg';

import styles from './index.module.scss';
import { any, object, string } from 'prop-types';

const TileCard = ({ title, subtitle, subtext, imageUrl, url, search = '', sx={}, topAction=null, bottomAction=null }) => {
    const location = useLocation();

    return (
    <>
        <Box
            sx={{
                overflow: 'hidden',
                position: 'relative',
                height: '60vh',
                color: '#ffffff',
                border: '1px solid #000',
                background: `linear-gradient(to bottom, RGBA(0, 0, 0, 0.55), RGBA(0, 0, 0, 0) 57%, RGBA(0, 0, 0, 0.77)), url(${imageUrl}) no-repeat center center`,
                backgroundSize: "cover",
                position: 'relative',
                boxShadow: '-2px 10px 20px rgba(0,0,0,0.6) inset',
                ...sx
            }}
        >
            <Link
                to={{
                    pathname: url,
                    search: search || '',
                    state: {
                        from: `${location.pathname}${location.search}`
                    }
                }}
                style={{ textDecoration: 'none' }}
                className="w-100"
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    className={styles.tile_card}
                    sx={{
                        height: '100%'
                    }}
                >
                    {topAction}
                </Stack>
            </Link>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 9,
                    mb: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                    mx: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                }}
            >
                <Typography
                    sx={{
                        color: '#fff',
                        fontSize: '0.6rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}
                    noWrap={true}
                    component={'div'}
                >
                    {subtitle}
                </Typography>

                <Typography
                    variant="h2"
                    sx={{
                        my: 0,
                        color: '#ffffff',
                        fontSize: '2rem',
                        fontWeight: '500'
                    }}
                >
                    <Wysiwyg
                        truncatedAt={3}
                    >
                        {title}
                    </Wysiwyg>
                </Typography>

                <Box
                    className={styles.rich_text_for_old}
                >
                    <Typography
                        component="div"
                        sx={{
                            my: 0,
                            color: '#ffffff',
                            'ul': {
                                m: 0,
                            },
                            '.wysiwyg p': {
                                m: 0,
                            },
                        }}
                    >
                        <Wysiwyg
                            truncatedAt={5}
                        >
                            {subtext}
                        </Wysiwyg>
                    </Typography>
                </Box>

                <Box>
                    {bottomAction}
                </Box>
            </Box>
        </Box>
    </>
    )
};

TileCard.propTypes = {
    bottomAction: any,
    imageUrl: string.isRequired,
    title: string.isRequired,
    subtitle: string,
    subtext: string,
    sx: object,
    url: string.isRequired,
    topAction: any,
}

export default TileCard;