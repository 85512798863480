import { Box, Container } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import api from "../../api";

import Collection from "../../components/Collection";
import Loader from "../../components/Loader";
import notify from "../../components/Notification/helper";
import PageHeader from "../../components/PageHeader";
import Cuisine from "../../layouts/Cuisine";
import Cuisines from "../../layouts/Cuisines";

import { getTranslation } from "../../utils/translate";
import { useUpdateDocumentMeta } from "../../hooks/useUpdateDocumentMeta";

import './index.scss';

function CollectionPage() {
  const { collectionId } = useParams();

  const language = useSelector(state => state.language);
  
  const [collection, setCollection] = useState({});
  const [loading, setLoading] = useState(false);
  const [beefCuts, setBeefCuts] = useState([]);
  const [cuisines, setCuisines] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [recipeAuthors, setRecipeAuthors] = useState([]);
  
  const apiFieldsToFetch = [
    'icon',
    'id',
    'items.collection',
    'items.id',
    'items.item.*',
    'items.sort',
    'items.item.beef_product_overrides.id',
    'items.item.beef_product_overrides.retailer',
    'items.item.beef_product_overrides.status',
    'items.item.beef_product_overrides.translations.*',
    'items.item.category.status',
    'items.item.category.translations.*',
    'items.item.retailers.retailer_id',
    'items.item.retailers.sort',
    'items.item.items.*',
    'items.item.imagegallery.*',
    'items.item.layout_translations.*',
    'items.item.translations.biography',
    'items.item.translations.content',
    'items.item.translations.external',
    'items.item.translations.image',
    'items.item.translations.label',
    'items.item.translations.languages_code',
    'items.item.translations.languages_id',
    'items.item.translations.name',
    'items.item.translations.title',
    'items.item.translations.url',
    'sort',
    'translations.*',
    'type',
  ];

  useUpdateDocumentMeta();

  useEffect(async() => {
    try {
      window.scrollTo(0, 0);
      setLoading(true);

      const getCollectionResponse = await api.getCollection(collectionId, apiFieldsToFetch);
      setCollection(getCollectionResponse);

      setBeefCuts(getCollectionResponse.items
        ?.filter(junction => junction.collection === 'beef_product')
        ?.map(junction => junction.item)
      )
      
      setCuisines(getCollectionResponse.items
        ?.filter(junction => junction.collection === 'collections')
        ?.map(junction => junction.item)
      );

      setRecipes(getCollectionResponse.items
        ?.filter(junction => junction.collection === 'recipe')
        ?.map(junction => junction.item)
      );

      setRecipeAuthors(getCollectionResponse.items
        ?.filter(junction => junction.collection === 'recipe_author')
        ?.map(junction => junction.item)
        ?.filter(individual => individual.status === 'published')
      );
    } catch(error) {
      error?.map((err) => notify('error', err.message));
    } finally {
      setLoading(false);
    }
  }, [collectionId]);

  if (loading) {
    return <Loader />
  }
  
  return (
    <Box
      className="collection"
    >
      <Container maxWidth disableGutters
        sx={{ p: 0 }}
      >
        <Box>
          <PageHeader item={{ 
            name: getTranslation(collection, { key: 'languages_code', code: language.code }).title,
            recipe: [],
            svg_icon: collection.icon
          }}
          useAsLink={false}
        />
        </Box>
      </Container>

      <Container
        sx={{
          my: 5
        }}
      >
        {
          collection.type === 'CUISINE' && 
          <Cuisine
            title={
              getTranslation(
                collection,
                {
                  key: 'languages_code',
                  code: language.code
                }
              ).title
            }
            description={
              getTranslation(
                collection,
                {
                  key: 'languages_code',
                  code: language.code
                }
              ).content
            }
            recipeAuthors={recipeAuthors}
            beefCuts={beefCuts}
            recipes={recipes}
            imageUrl={getTranslation(collection, { key: 'languages_code', code: language.code }).image ? `${process.env.REACT_APP_API_URL}/assets/${getTranslation(collection, { key: 'languages_code', code: language.code }).image}?key=page-hero-image` : null}
          />
        }

        {
          collection.type === 'CUISINES' && 
          <Cuisines
            title={
              getTranslation(
                collection,
                {
                  key: 'languages_code',
                  code: language.code
                }
              ).title
            }
            description={
              getTranslation(
                collection,
                {
                  key: 'languages_code',
                  code: language.code
                }
              ).content
            }
            cuisines={cuisines}
            imageUrl={`${process.env.REACT_APP_API_URL}/assets/${getTranslation(collection, { key: 'languages_code', code: language.code }).image}?key=page-hero-image`}
          />
        }

        {
          collection.type === 'BEEF_CUT' && 
          <Collection
            id={collectionId}
            content
          />
        }

        {
          !!!collection.type && 
          <Collection
            id={collectionId}
            content
          />
        }
      </Container>
    </Box>
  )
}

export default CollectionPage