import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useSWR from 'swr';

import { Chip, Container, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';

import linkifyHtml from "linkify-html";

import DownloadIcon from '../../shared/svg/download.svg';

import constants from '../../utils/constants';
import { fetcher } from '../../utils/fetcher';
import { getTranslation } from '../../utils/translate';
import { formatBytes } from '../../utils/files';
import { useUpdateDocumentMeta } from '../../hooks/useUpdateDocumentMeta';

import PageHeader from '../../components/PageHeader';
import Wysiwyg from '../../components/Wysiwyg';
import Videos from '../../components/Videos';
import Loader from '../../components/Loader';

import styles from './index.module.scss';
import ListItem from '../../components/ListItem';
import Gallery from '../../components/Gallery';
import TextBlock from '../../components/TextBlock';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { getAuthorDisplayName } from '../../utils/author';

function Article() {
  const { code:languageCode } = useSelector(state => state.language);

  const articleId = useParams().id;

  const apiFieldsToFetch = [
    '*',
    'authors.recipe_author_id.*',
    'category.id',
    'category.translations.*',
    'tags.tags_id.*',
    'tags.tags_id.translations.*',
    'translations.*',
    'blocks.*',
    'blocks.item.*',
    'blocks.item.images.translations.*',
    'blocks.item.translations.*',
    'blocks.item.translations.file.*',
    'blocks.item.videos.translations.*',
  ];

  const filter = {
    status: 'published',
  }

  const { data:article, error:articleError, isLoading:isArticleLoading } = useSWR({url: `pages/${articleId}`, fields: apiFieldsToFetch, filter}, fetcher);

  let translatedArticle = {};

  if (!!article) {
    translatedArticle = getTranslation(article, { key: 'languages_code', code: languageCode });
  }

  useUpdateDocumentMeta(translatedArticle?.title);

  if (isArticleLoading) {
    return <Loader />
  }

  return (
    <Box
      className={'author'}
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: translatedArticle?.title,
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            my: 3,
          }}
        >
          <Breadcrumbs
            home
            links={[
              {
                url: '/articles',
                content: t('articles')
              },
              ...(!!article?.category ? [
                {
                  content: getTranslation(article.category, { key: 'languages_code', code: languageCode })?.name,
                  url: `/articles/category/${article?.category.id}`
                }
              ] : []),
            ]}
          />
        </Box>

        {
          !!article?.image &&
          <Box
            sx={{
              mb: 3
            }}
          >
            <img
              className={styles.hero_image}
              src={`${process.env.REACT_APP_API_URL}/assets/${article.image}?key=page-hero-image`}
              alt={translatedArticle?.title}
            />
          </Box>
        }

        <Box>
          <Typography
            variant='div'
            sx={{
              fontStyle: 'italic',
              textAlign: 'justify',
              fontSize: '0.8rem'
            }}
          >
            <Wysiwyg>{translatedArticle?.summary}</Wysiwyg>
          </Typography>

          <Box>
            {
              article?.tags?.map(({tags_id:tag}) => (
                <Chip
                  key={tag.id}
                  variant={'outline'}
                  label={getTranslation(tag, {key: 'languages_code', code: languageCode}).name}
                  sx={{
                    mr: 0.5,
                    my: 1
                  }}
                />
              ))
            }
          </Box>
        </Box>

        <Grid
          container
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Stack
            >
              {
                article?.authors.map(({recipe_author_id:author}) => (
                  <ListItem
                    variant='compact'
                    key={author.id}
                    url={`/author/${author.id}`}
                    image={`${process.env.REACT_APP_API_URL}/assets/${author.image}?key=page-hero-image-contain`}
                    title={getAuthorDisplayName(author)}
                    subtitle={author.credentials}
                  />
                ))
              }
            </Stack>
          </Grid>

          <Grid
            item
            sm={12}
          >
            <Box>
              {
                article?.blocks
                  ?.filter(({ item:block }) => block?.status === 'published')
                  ?.map(block => (
                  <Box
                    key={block.id}
                    sx={{
                      mt: 2,
                      mb: 5,
                    }}
                  >
                    {
                      !!(block.collection === 'block_file') &&
                      <Box
                        sx={{
                          background: '#F0EDEC',
                          my: 3,
                          px: 1
                        }}
                      >
                      <ListItem
                        target='_blank'
                        variant={'compact'}
                        image={DownloadIcon}
                        title={getTranslation(block.item, { key: 'languages_code', code: languageCode}).name}
                        subtitle={formatBytes(getTranslation(block.item, { key: 'languages_code', code: languageCode}).file.filesize)}
                        url={`${process.env.REACT_APP_API_URL}/assets/${getTranslation(block.item, { key: 'languages_code', code: languageCode}).file.id}`}
                      />
                      </Box>
                    }

                    {
                      !!(block.collection === 'block_videos') &&
                      <Videos
                        mode='dark'
                        title={getTranslation(block.item, { key: 'languages_code', code: languageCode})?.title}
                        content={
                          (
                            linkifyHtml(
                              getTranslation(block.item, { key: 'languages_code', code: languageCode})?.content || '', 
                              { className: styles.link_underline }
                            )
                          )
                        }
                        videos={block.item.videos.map(videoBlock => {
                          const translatedVideoBlock = getTranslation(videoBlock, { key: 'languages_code', code: languageCode });
                          const sourceUrl = translatedVideoBlock.type === 'VIMEO' ?
                            `${constants.VIMEO_PLAYER_URL}/${translatedVideoBlock.video_id}` :
                            `${constants.YOUTUBE_PLAYER_URL}/${translatedVideoBlock.video_id}`

                          return {
                            id: videoBlock.id,
                            sourceUrl,
                            videoType: translatedVideoBlock.type,
                          }
                        })}
                      />
                    }

                    {
                      !!(block.collection === 'block_gallery') &&
                      <Gallery
                        title={getTranslation(block.item, { key: 'languages_code', code: languageCode})?.title}
                        content={
                          (
                            linkifyHtml(
                              getTranslation(block.item, { key: 'languages_code', code: languageCode})?.content || '', 
                              { className: styles.link_underline }
                            )
                          )
                        }
                        imageBlocks={block.item.images.map(imageBlock => {
                          const translatedImageBlock = getTranslation(imageBlock, { key: 'languages_code', code: languageCode });

                          return {
                            id: imageBlock.id,
                            imageUrl: `${process.env.REACT_APP_API_URL}/assets/${translatedImageBlock.image}?key=article-image`,
                            caption: translatedImageBlock.caption,
                            title: translatedImageBlock.title,
                            content: translatedImageBlock.content,
                          }
                        })}
                      />
                    }

                    {
                      !!(block.collection === 'block_richtext') &&
                      <TextBlock
                        title={getTranslation(block.item, { key: 'languages_code', code: languageCode})?.title}
                        content={
                          (
                            linkifyHtml(
                              getTranslation(block.item, { key: 'languages_code', code: languageCode})?.content || '', 
                              { className: styles.link_underline }
                            )
                          )
                        }
                      />
                    }
                  </Box>
                ))
              }
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Article