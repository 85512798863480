import { useTranslation } from "react-i18next";

import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';

import Wysiwyg from "../../Wysiwyg";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Close } from "@mui/icons-material";

export const DialogContestFooter = ({ handleClose, rules }) => {
    const { t } = useTranslation(['gamification', 'provinces']);

    return (
        <Box
            sx={{}}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    color: '#000',
                    position: 'absolute',
                    top: '0.15rem',
                    right: '0.25rem',
                    zIndex: '1',
                }}
            >
                <Close fontSize="interit" />
            </IconButton>
            {rules==true?(
            <div>
            <Typography
                sx={{
                    color: '#1A1511',
                    fontWeight: '300',
                    fontSize: '0.5rem',
                    lineHeight: '0.6rem',
                    textAlign: 'justify',
                    mb: 0.5,
                }}
            >
                <Wysiwyg>
                    {t('enterContestTerms')}
                </Wysiwyg>
            </Typography>

            <Link
                to={'/contest'}
                target={'_blank'}
            >
                <Typography
                    sx={{
                        color: '#1A1511',
                        fontWeight: 'bold',
                        fontSize: '0.75rem',
                        textAlign: 'center',
                        textTransform: 'lowercase',
                        textDecoration: 'underline',
                    }}
                >
                    <Wysiwyg>
                        {t('enterContestRules')}
                    </Wysiwyg>
                </Typography>
            </Link></div>):(<div></div>)
        }
        </Box>
    )
}