import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Close } from "@mui/icons-material";

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography, Grid } from "@mui/material";
import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';

import TrophyImage from '../../../shared/img/trophy.png';
import Stripes from '../../../shared/img/stripes.png';
import StarIcon from '../../../shared/img/star.png';
import ContestArt from '../../../shared/img/Bitmap.png';

import { getTranslation } from "../../../utils/translate";
import { useSelector } from "react-redux";
import { useFetchAchievements } from "../../../hooks/useFetchAchievements";

import DialogShare from "../DialogShare";
import { useFetchPoints } from "../../../hooks/useFetchPoints";
import Achievement from "../Achievement";
import Wysiwyg from "../../Wysiwyg";
import { useGetContests } from "../../../hooks/useGetContests";
import RetailerContext from "../../../context";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            unmountOnExit
            {...props}
        />
    );
});

const MyAchievementsDialogoxBox = (props) => {
    const { t } = useTranslation(['gamification']);
    const { code: languageCode } = useSelector(state => state.language);

    const { open = false, onClose, title = t('achievementsTitle') } = props;

    const { retailer } = useContext(RetailerContext);

    const { data } = useFetchAchievements();
    const { totalPoints: points } = useFetchPoints();

    const achievementsList = data?.map(achievement => {
        const translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });
        const imageUrl = achievement.image ?
            `${process.env.REACT_APP_API_URL}/assets/${achievement.image}?key=achievement-image` :
            TrophyImage;

        return {
            id: achievement.id,
            title: translatedAchievement.title,
            type: achievement.type,
            completed: false,
            points: achievement.points,
            target: achievement.target,
            imageUrl,
            message: translatedAchievement.description,
        }
    }) || [];

    // add additional properties to the achievements
    const scanningAchievement = achievementsList?.find(achievement => achievement.id === process.env.REACT_APP_SCAN_ACHIEVEMENT_ID);

    if (scanningAchievement) {
        scanningAchievement.modalToOpen = 'watch-video-scan';
    }

    const contestRegistrationAchievement = achievementsList?.find(achievement => achievement.id === process.env.REACT_APP_CONTEST_ACHIEVEMENT_ID);

    if (contestRegistrationAchievement) {
        contestRegistrationAchievement.modalToOpen = 'contest-info';
        contestRegistrationAchievement.buttonText = t('enterSweepstakes');
    }

    const beefCutAchievement = achievementsList?.find(achievement => achievement.id === process.env.REACT_APP_BEEF_ACHIEVEMENT_ID);

    if (beefCutAchievement) {
        beefCutAchievement.modalToOpen = 'watch-video-beef';
    }

    const achievements = [
        ...(contestRegistrationAchievement ? [contestRegistrationAchievement] : []),
        ...(scanningAchievement ? [scanningAchievement] : []),
        ...(beefCutAchievement ? [beefCutAchievement] : []),
    ];

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    const hideShowContestButton = () => {
        let hideButton = ((contestRegistrationAchievement?.points / contestRegistrationAchievement?.target) * 100) >= 100;

        // if contest registration achievement is undefined, hide the button
        if (!contestRegistrationAchievement) {
            hideButton = true;
        }

        return hideButton;
    }

    return (
        <Dialog
            className='page-header'
            open={open}
            onClose={() => handleClose('promotion')}
            fullWidth={true}
            TransitionComponent={Transition}
            maxWidth={'xs'}
            scroll="paper"
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                },
            }}
        >
            <DialogTitle
            sx={{
                padding: '0 20px',
                height: '3em',
                marginTop: '1rem',
            }}>
            <Box
                    sx={{}}
                >
                    <Box>
                        <Grid container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid item
                                xs={8}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            s: '1.5rem',
                                            md: '1.5rem',
                                        },
                                        fontWeight: 'bolder',
                                        '& strong': {
                                            color: '#B92534',
                                        },
                                        lineHeight: '1'
                                    }}
                                >
                                    <Wysiwyg>
                                        {t('enterContestTitle')}
                                    </Wysiwyg>
                                </Typography>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    height: '3rem',
                                }}
                            >
                                <img
                                    className=""
                                    src={CanadaBeefLogo}
                                    alt={'wheel'}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <IconButton
                    onClick={() => handleClose('promotion')}
                    sx={{
                        color: '#000',
                        position: 'absolute',
                        top: '0.15rem',
                        right: '0.25rem',
                        zIndex: '1',
                    }}
                >
                    <Close fontSize="interit" />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{
                    overflowX: 'hidden',
                }}
            >
                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 2,
                        px: '1.5rem',
                        mx: '-1.5rem',
                    }}
                >
                    <img
                        src={Stripes}
                        alt={'Stripe Art'}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            right: 0,
                            left: 0,
                        }}
                    />

                    <Box
                        sx={{
                            textAlign: 'center',
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                mx: 'auto',
                            }}
                        >
                            <Box
                                sx={{
                                    height: {
                                        xs: 'auto',
                                    },
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={StarIcon}
                                    alt={'Points Icon'}
                                    style={{
                                        height: '3rem',
                                        width: 'auto',
                                        right: 0,
                                        left: 0,
                                    }}
                                />

                                <Stack
                                    alignItems={'center'}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                    }}
                                >
                                    <Typography
                                        component={'div'}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                            my: 'auto',
                                            WebkitTextStroke: '1px #fff',
                                        }}
                                    >
                                        {points}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 1,
                        mb: 2,
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#000',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            mt: 2,
                        }}
                    >
                        <Wysiwyg>
                            {t('achievementsDescription')}
                        </Wysiwyg>
                    </Typography>
                </Box>

                <Stack
                    sx={{
                        mb: 0,
                        mx: '-1.5rem',
                    }}
                    m={{
                        mb: 4,
                        mx: '-1.5rem',
                    }}
                >
                    {
                        achievements.map(achievement => (
                            <Box
                                key={achievement.title}
                            >
                                <Achievement
                                    item={achievement}
                                />
                            </Box>
                        ))
                    }
                </Stack>

                <Stack
                    justifyContent={'center'}
                    sx={{
                        mt: 4,
                    }}
                >
                    {
                        !isNaN(points) &&
                        <DialogShare />
                    }
                    <Box
                        sx={{
                            textAlign: 'center',
                            mt: 1,
                        }}
                    >
                        {
                            !hideShowContestButton() &&
                            <Button
                                variant="contained"
                                color="success"
                                sx={{
                                    backgroundColor: '#B3C141',
                                    fontFamily: retailer.button_font,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    mb: 1,
                                    borderRadius: '0',
                                }}
                                onClick={() => { handleClose('contest-info') }}
                            >
                                {t('enterSweepstakes')}
                            </Button>
                        }
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export const DialogMyAchievements = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <MyAchievementsDialogoxBox
                open={defaultOpen}
                onClose={handleClose}
                key={defaultOpen}
            />
        </Box>
    )
}