import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';
import { Box } from '@mui/system';

import styles from './index.module.scss';

function Wysiwyg(props) {
  const { component = 'div', allowedTags = [], children='', truncatedAt } = props;

  const config = {
    ALLOWED_TAGS: ['a', 'br', 'p', 'b', 'ul', 'ol', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'strong', 'span', 'div', ...allowedTags],
  };

  // if truncated at is set, truncate the content
  return (
    <Box
      className={'wysiwyg'}
      component={component}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children, config) }}
      sx={{
        ...(
          truncatedAt ? {
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp : truncatedAt,
            WebkitBoxOrient: "vertical"
          } : {}
        )
      }}
    />
  )
}

Wysiwyg.propTypes = {
  component: PropTypes.string,
  allowedTags: PropTypes.array,
  children: PropTypes.string,
  truncatedAt: PropTypes.number,
}

export default Wysiwyg;