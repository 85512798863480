import { useContext, useEffect } from "react";

import API from '../api';
import { useGetShortCode } from "./useGetShortCode";
import { setShortCode } from "../utils/gamification";

import { AwardsContext } from "../context/AwardsContext";
import RetailerContext from "../context";

export const useAwardPoints = (achievementId, collection, item, allowDuplicates, isValid=true, showModalOverride) => {
    const { setModalToOpenWithItem, setAchievement, showPointsAnimation } = useContext(AwardsContext);
    const { retailer } = useContext(RetailerContext);

    if (!achievementId) return;
    if (!isValid) return;

    let shortCode = useGetShortCode();

    const getPointsForAchievement = async (fields=[], filter={}) => {
        const achievementPoints = await API.getAchievementPoint(filter, fields);

        return achievementPoints;
    };

    const awardPoints = async (achievement, collection, item, showModal=false) => {
        const awardPointsPayload = {
            achievement: achievement.id,
            points: 1,
            short_code: shortCode,
            collection,
            item,
        };

        // post page view log
        await API.postAwardPoints(awardPointsPayload);

        if (showModal) {
            if (item === 'FIND_A_BEEF_CUT') {
                achievement.modalToOpen = 'watch-video-scan';
            }

            if (item === 'HOW_TO_SCAN') {
                achievement.modalToOpen = 'watch-video-beef';
            }

            setModalToOpenWithItem('award', achievement);
        } else {
            setAchievement(achievement);
            showPointsAnimation();
        }
    };

    const checkIfLastPoints = (achievement, pointsAlreadyAwarded) => {
        const { target } = achievement;

        return (pointsAlreadyAwarded + 1) >= target;
    };

    const checkIfTargetMet = (achievement, pointsAlreadyAwarded) => {
        const { target } = achievement;

        return pointsAlreadyAwarded >= target;
    };

    useEffect(async () => {
        // proceed only if retailer has gamification features enabled
        if (!retailer.gamification) {
            return;
        }

        if (!shortCode) {
            shortCode = await API.getShortCode();
            setShortCode(shortCode);
        }
        
        try {
            // get achievement
            let achievement = await API.getAchievement(
                achievementId,
                ['*', 'translations.*'],
                {
                    _and: [
                        {
                            status: {
                                _eq: 'published'
                            }
                        }
                    ]
                }
            );

            const fields = ['*'];
            const filter = {
                _and: [
                    {
                        achievement: {
                            _eq: achievementId
                        }
                    },
                    {
                        collection: {
                            _eq: collection
                        }
                    },
                    {
                        short_code: {
                            _eq: shortCode
                        }
                    },
                    {
                        item: {
                            _eq: item
                        }
                    },
                ]
            };

            const hasPageAlreadyBeenAwardedPoints = !!(await getPointsForAchievement(fields, filter))?.length;
            const pointsAlreadyAwardedForAchievement = await getPointsForAchievement(fields, {
                _and: [
                    {
                        achievement: {
                            _eq: achievementId
                        },
                    },
                    {
                        short_code: {
                            _eq: shortCode
                        }
                    },
                ]
            });

            achievement = {
                ...achievement,
                pointsAlreadyAwardedForAchievement: pointsAlreadyAwardedForAchievement?.length || 0
            }
            
            // check if achievement is already awarded
            if (!hasPageAlreadyBeenAwardedPoints) {
                // check if further points need to be awarded
                if (checkIfTargetMet(achievement, pointsAlreadyAwardedForAchievement?.length || 0)) {
                    return;
                }

                // check if these are the last points to award
                // if so, show the modal
                let showModal = (
                    checkIfLastPoints(achievement, pointsAlreadyAwardedForAchievement?.length || 0) ||
                    !pointsAlreadyAwardedForAchievement.length
                );

                if (showModalOverride !== undefined) {
                    showModal = showModalOverride;
                }

                awardPoints(
                    achievement,
                    collection,
                    item,
                    // check if these are the last points to award
                    showModal,
                    
                );
            } else {
                if (allowDuplicates) {
                    // check if further points need to be awarded
                    if (checkIfTargetMet(achievement, pointsAlreadyAwardedForAchievement?.length || 0)) {
                        return;
                    }

                    // check if these are the last points to award
                    // if so, show the modal
                    let showModal = (
                        checkIfLastPoints(achievement, pointsAlreadyAwardedForAchievement?.length || 0) ||
                        !pointsAlreadyAwardedForAchievement.length
                    );

                    if (showModalOverride !== undefined) {
                        showModal = showModalOverride;
                    }

                    awardPoints(
                        achievement,
                        collection,
                        item,
                        showModal,
                    );
                }
            }
        }
        catch (error) {
            // error.map((err) => notify('error', err.message));
        }
        finally {}
    }, []);
}