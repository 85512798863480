import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import useSWR from "swr";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import Loader from "../../components/Loader";

import RetailerContext from "../../context";

import { getTranslation } from "../../utils/translate";
import { getBeefProductOverride } from '../../utils/overrides';
import constants from "../../utils/constants";
import { fetcher } from "../../utils/fetcher";

import AccordionSection from "../../components/AccordionSection";
import Wysiwyg from "../../components/Wysiwyg";
import Videos from "../../components/Videos";
import TileCard from "../../components/TileCard";

import { useUpdateDocumentMeta } from "../../hooks/useUpdateDocumentMeta";

import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";
import Gallery from "../../components/Gallery";
import TileGrid from "../../components/shared/TileGrid";
import Search from "../../components/Search";

function BeefSubprimal() {
  const language = useSelector(state => state.language);
  const { retailer } = useContext(RetailerContext);
  const { t } = useTranslation();
  const { beefProductId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const fields = [
    'Alternate_Food_Service_Image',
    'additional_images.directus_files_id.id',
    'additional_images.directus_files_id.type',
    'beef_product_brandings.beef_product_branding_id.brand_color',
    'beef_product_brandings.beef_product_branding_id.id',
    'beef_product_brandings.beef_product_branding_id.image',
    'beef_product_brandings.beef_product_branding_id.logo',
    'beef_product_brandings.beef_product_branding_id.translations.description',
    'beef_product_brandings.beef_product_branding_id.translations.id',
    'beef_product_brandings.beef_product_branding_id.translations.languages_code',
    'beef_product_brandings.beef_product_branding_id.translations.name',
    'beef_product_brandings.beef_product_branding_id.retailers.retailer_id',
    'beef_product_brandings.beef_product_branding_id.retailer',
    'beef_product_brandings.beef_product_branding_id.status',
    'beef_product_overrides.image',
    'beef_product_overrides.retailer',
    'beef_product_overrides.status',
    'beef_product_overrides.svg_icon',
    'beef_product_overrides.translations.description',
    'beef_product_overrides.translations.languages_code',
    'beef_product_overrides.translations.name',
    'catalog_code',
    'collections.*.*',
    'children.beef_product_overrides.*',
    'children.beef_product_overrides.translations.*',
    'children.*',
    'children.recipes.recipe_id.retailers',
    'children.translations.*',
    'children.food_service_configurations.*',
    'cooking_methods.id',
    'cooking_methods.cooking_method.translations.id',
    'cooking_methods.cooking_method.translations.languages_code',
    'cooking_methods.cooking_method.translations.Method',
    'cooking_methods.Recommended',
    'cooking_methods.translations.id',
    'cooking_methods.translations.languages_code',
    'cooking_methods.translations.Directions',
    'external_url',
    'food_service_configurations.*',
    'food_service_related_cuts.*.*',
    'food_service_related_cuts.*.translations.*',
    'food_service_related_cuts.*.food_service_configurations.*',
    'id',
    'image',
    'merchandising_sections.*',
    'merchandising_sections.item.id',
    'merchandising_sections.item.status',
    'merchandising_sections.item.translations.*',
    'merchandising_sections.item.videos.id',
    'merchandising_sections.item.videos.status',
    'merchandising_sections.item.videos.translations.*',
    'key_information_blocks.*',
    'key_information_blocks.item.id',
    'key_information_blocks.item.status',
    'key_information_blocks.item.translations.*',
    'key_information_blocks.item.videos.id',
    'key_information_blocks.item.videos.status',
    'key_information_blocks.item.videos.translations.*',
    'Nutrients.calories',
    'Nutrients.id',
    'Nutrients.translations.id',
    'Nutrients.translations.description',
    'Nutrients.translations.languages_code',
    'Nutrients.translations.macronutrients',
    'Nutrients.translations.micronutrients',
    'Nutrients.translations.portion_size',
    'primal_configurations.*',
    'primal_configurations.visual_guide_images.directus_files_id.id',
    'primal_configurations.visual_guide_images.directus_files_id.type',
    'svg_icon',
    'translations.id',
    'translations.description',
    'translations.languages_id',
    'translations.name',
    'translations.specifications',
    'translations.tips',
    'translations.vimeo',
    'translations.youtube',
    'visual_guide_blocks.*',
    'visual_guide_blocks.item.id',
    'visual_guide_blocks.item.images.id',
    'visual_guide_blocks.item.images.status',
    'visual_guide_blocks.item.images.translations.*',
    'visual_guide_blocks.item.status',
    'visual_guide_blocks.item.translations.*',
    'visual_guide_blocks.item.videos.id',
    'visual_guide_blocks.item.videos.status',
    'visual_guide_blocks.item.videos.translations.*',
  ];

  const { data:beefProduct, error, isLoading: beefProductLoading } = useSWR({
      url: `beef_product/${beefProductId}`,
      fields,
    }, fetcher);
  
  const recipeFields = 'id,total_time,ingredients_list,layout_translations.*,imagegallery.*,portions,difficulty,yields,cooking_method.cooking_method_id.translations.*';

  // fetch recipes
  const recipeFilters = {
    '_and': ([
      {
        '_or': [
          {
            retailers: {
              retailer_id: {
                '_eq': retailer.id
              }
            }
          },
          {
            retailer: {
              id: {
                '_eq': retailer.id
              }
            }
          }
        ]
      },
      {
        status: {
          '_eq': 'published'
        }
      },
      ...[
        !!beefProductId ?
          {
            beef_products: {
              beef_product_id: {
                '_eq': beefProductId
              }
            },
          } : {}
      ],
    ])
  };

  const {
    data: recipes,
    isLoading: recipesLoading
  } = useSWR({ url: `recipe`, fields: recipeFields, filter: recipeFilters }, fetcher);
  
  useUpdateDocumentMeta(beefProduct?.name);

  let foodServiceData = {};
  let primalCutData = {};

  let visualGuideImages = [];
  let visualGuideVideos = [];
  let purchasingVideos = [];
  let preparationVideos = [];
  let culinaryVideos = [];
  let merchandisingVideos = [];
  let relatedCuts = [];
  let childCuts = [];

  if (!!beefProduct) {
    primalCutData = getTranslation(
      beefProduct,
      {
        key: 'languages_code',
        code: language.code
      },
      'primal_configurations',
    );

    visualGuideImages = primalCutData?.visual_guide_images
      ?.filter(imageBlock => imageBlock?.directus_files_id?.type?.startsWith('image'))
      ?.filter(imageBlock => !!imageBlock?.directus_files_id?.id)
      ?.map(imageBlock => {
        const imageUrl = `${process.env.REACT_APP_API_URL}/assets/${imageBlock.directus_files_id.id}?key=article-image`;

        return {
          id: imageBlock.id,
          imageUrl,
        }
      }) || [];

    visualGuideVideos = primalCutData?.visual_guide_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
      
    foodServiceData = getTranslation(
      beefProduct,
      {
        key: 'languages_code',
        code: language.code
      },
      'food_service_configurations',
    );
    
    preparationVideos = foodServiceData?.preparation_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
    
    culinaryVideos = foodServiceData?.culinary_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];

    merchandisingVideos = foodServiceData?.merchandising_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
    
    purchasingVideos = foodServiceData?.purchasing_videos
      ?.map(video => {
        const sourceUrl = video.type === 'VIMEO' ?
          `${constants.VIMEO_PLAYER_URL}/${video.video_id}` :
          `${constants.YOUTUBE_PLAYER_URL}/${video.video_id}`

        return {
          id: video.id,
          sourceUrl,
          videoType: video.type,
        }
      }) || [];
    
    relatedCuts = beefProduct?.food_service_related_cuts?.map(({ related_beef_product_id: beefCut }) => {
      if (!beefCut) {
        return null;
      } else {
        const beefCutOverride = getBeefProductOverride(beefCut, retailer, language);
        const translatedBeefCutOverride = getTranslation(
          beefCutOverride,
          {
            key: 'languages_code',
            code: language.code
          },
        );

        const translatedBeefCut = getTranslation(
          beefCut,
          {
            key: 'languages_id',
            code: language.code
          }
        );

        const foodServiceConfigurations = getTranslation(
          beefCut,
          {
            key: 'languages_code',
            code: language.code
          },
          'food_service_configurations'
        );

        const specifications =
          foodServiceConfigurations?.specifications ||
          translatedBeefCutOverride?.specifications ||
          translatedBeefCut?.specifications;

        const image = beefCut?.Alternate_Food_Service_Image || beefCut?.image;

        return {
          id: beefCut.id,
          url: `/recipe-list`,
          search: `?beef_cut=${beefCut.id}`,
          title: translatedBeefCutOverride.name || translatedBeefCut.name,
          subtext: specifications,
          imageUrl: image ? `${process.env.REACT_APP_API_URL}/assets/${image}?key=tile-background-image-beef-wholesale` : null,
        }
      }
    });

    childCuts = beefProduct?.children?.map(beefCut => {
      if (!beefCut) {
        return null;
      } else {
        const beefCutOverride = getBeefProductOverride(beefCut, retailer, language);
        const translatedBeefCutOverride = getTranslation(
          beefCutOverride,
          {
            key: 'languages_code',
            code: language.code
          },
        );

        const translatedBeefCut = getTranslation(
          beefCut,
          {
            key: 'languages_id',
            code: language.code
          }
        );

        const foodServiceConfigurations = getTranslation(
          beefCut,
          {
            key: 'languages_code',
            code: language.code
          },
          'food_service_configurations'
        );

        const specifications =
          foodServiceConfigurations?.specifications ||
          translatedBeefCutOverride?.specifications ||
          translatedBeefCut?.specifications;

        const image = beefCut?.Alternate_Food_Service_Image || beefCut?.image;

        return {
          id: beefCut.id,
          url: `/recipe-list`,
          search: `?beef_cut=${beefCut.id}`,
          title: translatedBeefCutOverride.name || translatedBeefCut.name,
          subtext: specifications,
          imageUrl: image ? `${process.env.REACT_APP_API_URL}/assets/${image}?key=tile-background-image-beef-wholesale` : null,
        }
      }
    });

    const beefOverride = getBeefProductOverride(beefProduct, retailer, language);
    const translatedBeefOverride = getTranslation(
      beefOverride,
      {
        key: 'languages_code',
        code: language.code
      },
    );
    
    const translatedBeef = getTranslation(
      beefProduct,
      {
        key: 'languages_id',
        code: language.code
      }
    );  

    // handle beef name & description overrides
    beefProduct.name = translatedBeefOverride.name || translatedBeef.name;
    beefProduct.description = translatedBeefOverride.description || translatedBeef.description;

    // handle beef image & icon overrides
    beefProduct.image = translatedBeefOverride.image || beefProduct.image;
    beefProduct.svg_icon = translatedBeefOverride.svg_icon || beefProduct.svg_icon;

    // specifications foldable
    beefProduct.specifications = foodServiceData?.specifications || translatedBeefOverride.specifications || translatedBeef.specifications;

    // build videos data
    beefProduct.videos = [
      ...(
        translatedBeef.vimeo?.map(vimeo => {
          const id = vimeo.vimeo_id.replace('https://vimeo.com/', '');

          return {
            id,
            sourceUrl: `${constants.VIMEO_PLAYER_URL}/${id}`,
            videoType: 'vimeo',
          }
        }) || []
      ),
      ...(
        translatedBeef.youtube?.map(youtube => ({
          id: youtube.youtube_id,
          sourceUrl: `${constants.YOUTUBE_PLAYER_URL}/${youtube.youtube_id}`,
          videoType: 'youtube',
        })) || []
      )
    ];
  }

  const handleSearchTriggered = () => {
    // hide recipe list
    setIsSearchActive(true);
  };

  const handleSearchCleared = async () => {
    setIsLoading(false);
    // show recipe list
    setIsSearchActive(false);
  };

  if (isLoading || beefProductLoading) {
    return <Loader />
  }
  
  return (
    <Box
      className="beef-product"
    >
      <Container maxWidth disableGutters
        sx={{
          p: 0
        }}
      >
        <Box>
          <PageHeader
            item={{ 
              name: beefProduct?.name,
            }}
            useAsLink={false}
          />
        </Box>
      </Container>

      <Container
        fixed
        className='padding-on-mobile'
        sx={{
            mt: 5
        }}
      >
        {
          !!beefProduct?.Alternate_Food_Service_Image &&
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              className='accordion_section_img_container'
              item
              sm={12}
              sx={{
                mb: 3,
                textAlign: 'center'
              }}
            >
              <img
                className="accordion_section_img"
                src={`${process.env.REACT_APP_API_URL}/assets/${beefProduct.Alternate_Food_Service_Image}?key=beef-cut-image`}
                alt={getTranslation(beefProduct).name}
              />
            </Grid>
          </Stack>
        }

        {
          !!!beefProduct?.Alternate_Food_Service_Image && !!beefProduct?.image &&
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Grid
              className='accordion_section_img_container'
              item
              sm={12}
              sx={{
                mb: 3,
                textAlign: 'center'
              }}
            >
              <img
                className="accordion_section_img"
                src={`${process.env.REACT_APP_API_URL}/assets/${beefProduct.image}?key=beef-cut-image`}
                alt={getTranslation(beefProduct).name}
              />
            </Grid>
          </Stack>
        }
      </Container>

      <Container
        fixed
        sx={{
          mb: 3,
          px: {
            xs: 0,
            md: '1.5rem'
          }
        }}
      >
        {
          (!!beefProduct?.specifications) &&
          <AccordionSection
            title={t('pointsRequiringSpecification')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3,
              }}
            >
              <Typography
                className='search-message'
                sx={{
                  color: '#000',
                  fontWeight: 300,
                  fontSize: '18px',
                  textAlign: 'justify'
                }}
                component="div"
              >
                <Wysiwyg>
                  {beefProduct?.specifications}
                </Wysiwyg>
              </Typography>
            </Box>
          </AccordionSection>
        }

        {
          (!!beefProduct?.food_service_related_cuts?.length) &&
          <AccordionSection
            title={t('relatedCuts')}
            expanded={true}
            accordionDetailsProps={{
              sx: {
                px: {
                  xs: 0,
                  md: 2,
                },
              }
            }}
          >
            <Box
              sx={{
                my: 3
              }}
            >
              <Grid container>
                {
                  relatedCuts
                  ?.sort((a, b) => {
                    const nameA = a.title;
                    const nameB = b.title;

                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  ?.map(relatedCut => (
                    <Grid item
                      xs={12}
                      md={6}
                    >
                      <TileCard
                        key={relatedCut.id}
                        url={relatedCut.url}
                        search={relatedCut.search}
                        title={relatedCut.title}
                        subtext={relatedCut.subtext}
                        imageUrl={relatedCut.imageUrl}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </AccordionSection>
        }

        {
          !!foodServiceData.key_information &&
          <AccordionSection
            title={t('keyInformation')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3
              }}
            >
              <Typography
                component={'div'}
              >
                <Wysiwyg>
                  {foodServiceData.key_information}
                </Wysiwyg>
              </Typography>
            </Box>
          </AccordionSection>
        }

        {
          (!!preparationVideos.length) &&
          <AccordionSection
            title={t('preparation')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3,
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  videos={preparationVideos}
                />
              </Box>
            </Box>
          </AccordionSection>
        }

        {
          (!!culinaryVideos.length) &&
          <AccordionSection
            title={t('culinary')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3,
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  title=""
                    videos={culinaryVideos}
                />
              </Box>
            </Box>
          </AccordionSection>
        }

        {
          (!!merchandisingVideos.length || !!beefProduct?.children?.length) &&
          <AccordionSection
            title={t('merchandising')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  mb: 2,
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  title=""
                  videos={merchandisingVideos}
                />
              </Box>

              <Grid container>
                {
                  childCuts
                  ?.sort((a, b) => {
                    const nameA = a.title;
                    const nameB = b.title;

                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  ?.map(relatedCut => (
                    <Grid item
                      xs={12}
                      md={6}
                    >
                      <TileCard
                        key={relatedCut.id}
                        url={relatedCut.url}
                        search={relatedCut.search}
                        title={relatedCut.title}
                        subtext={relatedCut.subtext}
                        imageUrl={relatedCut.imageUrl}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </AccordionSection>
        }

        {
          (!!primalCutData.external_url || !!visualGuideImages.length || !!visualGuideVideos.length) &&
          <AccordionSection
            title={t('visualGuides')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3,
              }}
            >
              <Gallery
                variant={'grid'}
                imageBlocks={visualGuideImages}
              />
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  videos={visualGuideVideos}
                />
              </Box>

              {
                !!primalCutData.external_url &&
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  justifyContent={'center'}
                  sx={{
                    textAlign: 'center',
                    mt: 3,
                    color: '#000'
                  }}
                >
                  <ThreeDRotation
                    fontSize="large"
                  />

                  <a href={primalCutData.external_url} target="_blank" rel="noreferrer">
                    <Typography
                      component={'span'}
                      sx={{
                        color: '#000',
                        textDecoration: 'underline',
                      }}
                    >
                      {t('visualGuideExternalLink')}
                    </Typography>
                  </a>
                </Stack>
              }
            </Box>
          </AccordionSection>
        }

        {
          !!purchasingVideos.length > 0 &&
          <AccordionSection
            title={t('purchasing')}
            expanded={true}
          >
            <Box
              sx={{
                my: 3,
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Videos
                  sx={{
                    p: 0,
                  }}
                  mode='light'
                  title=""
                  videos={purchasingVideos}
                />
              </Box>
            </Box>
          </AccordionSection>
        }

        {
          recipes?.length > 0 &&
          <AccordionSection
            title={t('recipes')}
            expanded={true}
            accordionDetailsProps={{
              sx: {
                px: {
                  xs: 0,
                  md: 2,
                },
              }
            }}
          >
            <Box
              sx={{
                my: 3
              }}
            >
                <Container
                  className={'padding-on-mobile'}
                  sx={{
                    py: 2
                  }}
                >
                  <Search
                    handleSearchTriggered={handleSearchTriggered}
                    handleSearchCleared={handleSearchCleared}
                    handleResultsFound={handleSearchTriggered}
                    storageKey='recipe-search'
                  />
                </Container>

                {
                  !isSearchActive &&
                  <TileGrid
                    beefProductId={beefProductId}
                    tiles={recipes}
                  />
                }
            </Box>
          </AccordionSection>
        }
      </Container>
    </Box>
  )
}

export default BeefSubprimal