import userSWR from 'swr';

import { fetcher } from '../utils/fetcher';
export const useFetchAchievement = (id) => {
    const fields = [
        '*',
        'translations.*',
    ];

    const filter = {
        _and: [
            {
                id: {
                    '_eq': id
                }
            },
        ]
    };

    const { data, error, isLoading } = userSWR({
        url: 'achievements',
        fields,
        filter,
        sort: '-date_created'
    }, fetcher);

    return {
        data,
        error,
        isLoading,
    };
};