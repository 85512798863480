import { useContext, useEffect, useState } from "react";

import { Box } from "@mui/material";

import { DialogContestNag } from "../DialogContestNag";
import { DialogContestInfo } from "../DialogContestInfo";
import { DialogContestForm } from "../DialogContestForm";
import { DialogContestSuccess } from "../DialogContestSuccess";

import api from "../../../api";

import { useGetShortCode } from "../../../hooks/useGetShortCode";

import notify from "../../Notification/helper";
import { DialogRestoreProgress } from "../DialogRestoreProgress";
import { DialogSocialShare } from "../DialogSocialShare";
import { AwardsContext } from "../../../context/AwardsContext";
import { DialogMyAchievements } from "../DialogMyAchievements";
import DialogAward from "../DialogAward";
import RetailerContext from "../../../context";
import { DialogContestInformation } from "../DialogContestInformation";
import { useTranslation } from "react-i18next";
import { DialogWatchVideo } from "../DialogWatchVideo";
import { DialogContestRegistrationSuccess } from "../DialogContestRegistrationSuccess";

export const ModalContainer = () => {
    const { t } = useTranslation(['gamification']);

    const shortCode = useGetShortCode();
    const [enteredContent, setEnteredContent] = useState(false);
    const [openContestNag, setOpenContestNag] = useState(false);
    const [openContestInfo, setOpenContestInfo] = useState(false);

    const { modalToOpen, setModalToOpen, showPointsAnimation } = useContext(AwardsContext);
    const { retailer } = useContext(RetailerContext);

    const videoUrlFindBeefCut = `${process.env.REACT_APP_API_URL}/assets/ae99fa35-4e63-4eed-a9b8-f103d099cd1f`;
    const videoUrlHowToScan = `${process.env.REACT_APP_API_URL}/assets/ae99fa35-4e63-4eed-a9b8-f103d099cd1f`;

    // TODO make pure function
    const getContestJunctionItems = async () => {
        try {
            const contestJunctionItems = await api.getJunctionItems('contests_short_codes', {
                filter: {
                    _and: [
                        {
                            contests_id: {
                                _eq: process.env.REACT_APP_CONTEST_ID,
                            },
                        },
                        {
                            short_codes_id: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            if (contestJunctionItems?.length) {
                setEnteredContent(true);
            } else {
                setEnteredContent(false);

                const pageViews = Number(sessionStorage.getItem('pageViewsCount'));

                if (pageViews < 0) {
                    setOpenContestInfo(true);
                }
            }
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    const onClose = (component = null) => {
        let componentToOpen = component;

        // if component is contest form, then check if user already entered
        if (component === 'contest-form') {
            // if user has already entered, then open contest form success
            if (enteredContent) {
                componentToOpen = 'contest-form-success';
            }
        }

        setModalToOpen(componentToOpen);
    };

    useEffect(async () => {
        if (retailer?.gamification) {
            await getContestJunctionItems();
        }
    }, []);

    useEffect(async () => {
        if (retailer?.gamification) {
            const pageViews = Number(sessionStorage.getItem('pageViewsCount'));

            if (pageViews >= 10) {
                setOpenContestNag(true);
            }
        }
    });

    if (!retailer?.gamification) {
        return null;
    }

    return (
        <Box>
            <DialogContestNag
                defaultOpen={modalToOpen === 'contest-nag' || openContestNag}
                onClose={
                    componentToOpen => {
                        sessionStorage.setItem('pageViewsCount', 0);
                        setOpenContestNag(false);

                        onClose(componentToOpen);
                    }
                }
            />

            <DialogContestInfo
                defaultOpen={modalToOpen === 'contest-info' || openContestInfo}
                onClose={
                    componentToOpen => {
                        // if component is award, then animate points
                        sessionStorage.setItem('pageViewsCount', 0);
                        setOpenContestInfo(false);

                        onClose(componentToOpen);
                    }
                }
            />

            <DialogContestForm
                defaultOpen={modalToOpen === 'contest-form'}
                onClose={onClose}
            />

            <DialogContestInformation
                defaultOpen={modalToOpen === 'promotion'}
                onClose={onClose}
                title={t('canadaBeefPromotion')}
                subtitle={t('enterContestSuccessTitle')}
            />

            <DialogContestSuccess
                defaultOpen={modalToOpen === 'contest-form-success'}
                onClose={onClose}
            />

            <DialogRestoreProgress
                defaultOpen={modalToOpen === 'restore-progress'}
                onClose={onClose}
            />

            <DialogSocialShare
                defaultOpen={modalToOpen === 'social-share'}
                onClose={onClose}
            />

            <DialogMyAchievements
                defaultOpen={modalToOpen === 'my-achievements'}
                onClose={onClose}
            />

            <DialogAward
                defaultOpen={modalToOpen === 'award'}
                onClose={
                    componentToOpen => {
                        // if component is award, then animate points
                        showPointsAnimation();

                        onClose(componentToOpen);
                    }
                }
            />

            <DialogWatchVideo
                defaultOpen={modalToOpen === 'watch-video-beef'}
                onClose={onClose}
                title={t('informationPageBeefCutTitle')}
                description={t('informationPageBeefCutDescription')}
                content={t('informationPageBeefCutContent')}
                videoUrl={videoUrlFindBeefCut}
                achievementId={process.env.REACT_APP_BEEF_ACHIEVEMENT_ID}
                collection={'find-a-beef-cut'}
                id={'FIND_A_BEEF_CUT'}
            />

            <DialogWatchVideo
                defaultOpen={modalToOpen === 'watch-video-scan'}
                onClose={onClose}
                title={t('informationPageScanTitle')}
                description={t('informationPageScanDescription')}
                content={t('informationPageScanContent')}
                videoUrl={videoUrlHowToScan}
                achievementId={process.env.REACT_APP_SCAN_ACHIEVEMENT_ID}
                collection={'how-to-scan'}
                id={'HOW_TO_SCAN'}
            />

            <DialogContestRegistrationSuccess
                achievementId={process.env.REACT_APP_CONTEST_ACHIEVEMENT_ID}
                collection={'contest-registration'}
                id={'CONTEST_REGISTRATION'}
                key={modalToOpen}
            />
        </Box>
    )
}