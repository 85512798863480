import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FilledInput, FormControl, IconButton, Input, InputAdornment, InputLabel, Stack, Typography } from "@mui/material";

import RetailerContext from '../../context';
import notify from '../Notification/helper';
import AccordionSection from '../AccordionSection';
import { Done } from '@mui/icons-material';

import TrophyImage from '../../shared/img/trophy.png';
import DialogShare from '../shared/DialogShare';
import DialogRestore from '../shared/DialogRestore';
import DialogAchievements from '../shared/DialogAchievements';
import DialogAward from '../shared/DialogAward';
import { AwardsContext } from '../../context/AwardsContext';

const BookmarkDialog = (props) => {
    const { retailer } = useContext(RetailerContext);
    const { t } = useTranslation();

    const { open = true, onClose, title = t('bookmarkThisPage') } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            className='page-header'
            open={open}
            onClose={handleClose}
            >
            <DialogTitle
                sx={{
                    boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
                    py: 3,
                }}
                component={'div'}
            >
                <Typography
                    className='title'
                    variant="h1"
                    sx={{
                        color: retailer.brand_color,
                        fontSize: '32px',
                        fontWeight: 'bolder',
                        textTransform: 'uppercase',
                        mr: 1 
                    }}
                >
                    { title }
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ px: 0, mt: 3 }}>
                <Container sx={{ mb: 2 }}>
                    <Typography
                        sx={{color: '#000', fontWeight: 300, fontSize: '18px' }}
                    >
                        <Trans>
                            {t('bookmarkThisPageDescription')}
                        </Trans>
                    </Typography>
                </Container>

                <AccordionSection title={t('bookmarkThisPageBrowserSafari')}>
                    <Box>{t('bookmarkThisPageBrowserSafariDescription') }</Box>
                </AccordionSection>

                <AccordionSection title={t('bookmarkThisPageBrowserFirefox')}>
                    <Box>{t('bookmarkThisPageBrowserFirefoxDescription') }</Box>
                </AccordionSection>

                <AccordionSection title={t('bookmarkThisPageBrowserChrome')}>
                    <Box>{t('bookmarkThisPageBrowserChromeDescription') }</Box>
                </AccordionSection>

                <AccordionSection title={t('bookmarkThisPageBrowserSamsung')}>
                    <Box>{t('bookmarkThisPageBrowserSamsungDescription') }</Box>
                </AccordionSection>
            </DialogContent>

            <DialogActions>
                <Button
                    sx={{ 
                        borderRadius: 0,
                        backgroundColor: retailer.button_background_color,
                        fontFamily: retailer.button_font,
                        height: '48px',
                        textTransform: 'uppercase',
                        ':hover': {
                            bgcolor: retailer.button_background_color,
                        },
                    }}
                    variant="contained"
                    onClick={handleClose}
                >{ t('ok') }</Button>
            </DialogActions>
        </Dialog>
    )
}

const ShareDialog = (props) => {
    const { t } = useTranslation();
    
    const { open = false, onClose, title = t("shareThisPage") } = props;

    const { retailer } = useContext(RetailerContext);

    const [hasCopyPermission, setHasCopyPermission] = useState(false);

    useEffect(() => {
        const checkCopyPermissions = async () => {
            // check if copy permission has been granted
            try {
                // get clipboard permissions
                const clipboardWritePermission = await navigator.permissions.query({ name: 'clipboard-write' });

                if (clipboardWritePermission?.state === 'granted') {
                    setHasCopyPermission(true)
                }
            } catch (error) {
                // hide copy link button
            }
        }

        checkCopyPermissions();
    }, [])

    const handleClose = () => {
        onClose();
    };

    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            notify('success', t('linkCopied'));
        } catch(error) {
            // do nothing
        }
    }

    return (
        <Dialog
            className='page-header'
            open={open}
            onClose={handleClose}
            >
            <DialogTitle
                sx={{
                    boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
                    py: 3,
                }}
                component={'div'}
            >
                <Typography
                    className='title'
                    variant="h1"
                    sx={{
                        color: retailer.brand_color,
                        fontSize: '32px',
                        fontWeight: 'bolder',
                        textTransform: 'uppercase',
                        mr: 1 
                    }}
                >
                    { title }
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ mt: 3 }}>
                <Typography
                    sx={{color: '#000', fontWeight: 300, fontSize: '18px', mb: 2 }}
                >
                    {t('shareThisPageMessage')}
                </Typography>

                <Stack>
                    <FormControl sx={{}} variant="standard">
                        <FilledInput
                            disableUnderline={true}
                            hiddenLabel={true}
                            id="standard-adornment-password"
                            type={'readonly'}
                            value={window.location.href}
                            endAdornment={
                                hasCopyPermission &&
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={copyLink}
                                    >
                                        <ContentCopyIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button
                    sx={{ 
                        borderRadius: 0,
                        backgroundColor: retailer.button_background_color,
                        fontFamily: retailer.button_font,
                        height: '48px',
                        textTransform: 'uppercase',
                        ':hover': {
                            bgcolor: retailer.button_background_color,
                        },
                    }}
                    variant="contained"
                    onClick={handleClose}
                >{ t('ok') }</Button>
            </DialogActions>
        </Dialog>
    )
}

const BackButton = ({ showBackButton = true }) => {
    const history = useHistory();
    const containerRef = useRef(null);

    const backUrl = history.location?.state?.from || '/';

    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [openBookmarkialog, setOpenBookmarkDialog] = useState(false);

    const { openAwardsDialog, setOpenAwardsDialog } = useContext(AwardsContext);
    const retailerContext = useContext(RetailerContext);

    const bookmarkThisPage = async () => {
        setOpenBookmarkDialog(true);
    }

    const shareThisPage = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: document.title,
                    text: document.title,
                    url: window.location.href
                })
            } else {
                setOpenShareDialog(true);
            }
        } catch(error) {
            console.log('error: ', error);
        }
    }

    const handleCloseDialogAward = async () => {
        setOpenAwardsDialog(false);
    }

    return (
        <Box
            className='no-print'
            sx={{ 
                background: retailerContext.retailer.button_background_color,
            }}
        >
            <ShareDialog open={openShareDialog} onClose={() => setOpenShareDialog(false)} />
            <BookmarkDialog open={openBookmarkialog} onClose={() => setOpenBookmarkDialog(false)} />

            <Container
                className='padding-on-mobile'
            >
                <Stack
                    direction={'row'}
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '52px'
                    }}
                >
                    {
                        showBackButton &&
                        <Box>
                            <Link
                                to={backUrl}
                                onClick={(event) => {
                                    if (backUrl === '/' && history.action === 'POP') {
                                        // do nothing
                                    } else {
                                        event.preventDefault();
                                        history.goBack();
                                    }
                                }}
                            >
                                <ArrowCircleLeftOutlinedIcon sx={{ color: 'white', fontSize: 32 }} />
                            </Link>
                        </Box>
                    }

                    <Box
                        sx={{
                            ml: 'auto',
                            mr: 1
                        }}
                    >
                        <Link
                            to={''}
                            onClick={(event) => {
                                event.preventDefault();
                                bookmarkThisPage();
                            }}
                        >
                            <BookmarkBorderIcon sx={{ color: 'white', fontSize: 32 }} />
                        </Link>
                    </Box>

                    <Box
                        sx={{}}
                    >
                        <Link
                            to={history.location?.state?.from || '/'}
                            onClick={(event) => {
                                event.preventDefault();
                                shareThisPage();
                            }}
                        >
                            <ShareIcon sx={{ ml: 'auto', color: 'white', fontSize: 32 }} />
                        </Link>
                    </Box>

                    {
                        retailerContext.retailer?.gamification &&
                        <Box
                            sx={{
                                ml: 1,
                            }}
                            ref={containerRef}
                        >
                            <DialogAchievements />
                        </Box>
                    }
                </Stack>
            </Container>
        </Box>
    )
}

export default BackButton;