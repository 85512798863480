import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Slide, Stack, Typography, Grid } from "@mui/material";
import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';
import RetailerContext from "../../../context";

import TrophyImage from '../../../shared/img/trophy.png';
import StarImage from '../../../shared/img/star.png';
import ContestArt from '../../../shared/img/Bitmap.png';

import { getTranslation } from "../../../utils/translate";
import { useSelector } from "react-redux";
import Wysiwyg from "../../Wysiwyg";
import { AwardsContext } from "../../../context/AwardsContext";
import api from "../../../api";
import notify from "../../Notification/helper";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import { Close } from "@mui/icons-material";
import SocialMediaIcons from "../SocialMediaIcons";
import { useFetchPoints } from "../../../hooks/useFetchPoints";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const AwardDialogoxBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification']);

    const {code: languageCode } = useSelector(state => state.language);

    const [enteredContentStatus, setEnteredContentStatus] = useState('');
    const [isThereAnotherAchievement, setIsThereAnotherAchievement] = useState('idle');
    const [nextAchievement, setNextAchievement] = useState(null);
    const [completedAchievementsCount, setCompletedAchievementsCount] = useState(null);

    const { totalPoints: points } = useFetchPoints();

    const shortCode = useGetShortCode();

    const { retailer } = useContext(RetailerContext);
    const { achievement } = useContext(AwardsContext);

    let translatedRetailer = {};
    if (retailer) {
        translatedRetailer = getTranslation(retailer, { key: 'languages_code', code: languageCode });
    }

    const getContestJunctionItems = async () => {
        try {
            const contestJunctionItems = await api.getJunctionItems('contests_short_codes', {
                filter: {
                    _and: [
                        {
                            contests_id: {
                                _eq: process.env.REACT_APP_CONTEST_ID,
                            },
                        },
                        {
                            short_codes_id: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            if (contestJunctionItems?.length) {
                setEnteredContentStatus('entered');
            } else {
                setEnteredContentStatus('not entered');
            }
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    const fetchAchievements = async (id) => {
        try {
            // check if user has already earned achievement
            const contestJunctionItems = await api.getJunctionItems('points', {
                filter: {
                    _and: [
                        {
                            achievement: {
                                _eq: id,
                            },
                        },
                        {
                            short_code: {
                                _eq: shortCode
                            },
                        }
                    ]
                }
            });

            return contestJunctionItems
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }

    // order is important here
    const orderedAchievementsList = [
        {
            id: process.env.REACT_APP_CONTEST_ACHIEVEMENT_ID,
            modalToOpen: 'contest-info',
        },
        {
            id: process.env.REACT_APP_SCAN_ACHIEVEMENT_ID,
            modalToOpen: 'watch-video-scan',
        },
        {
            id: process.env.REACT_APP_BEEF_ACHIEVEMENT_ID,
            modalToOpen: 'watch-video-beef',
        },
    ];

    const shareMessage = `I just earned an achievement on ${translatedRetailer.retailer_name}`;
    const shareImageUrl = achievement.image ? `${process.env.REACT_APP_API_URL}/assets/${achievement.image}?key=open-graph-image` : null;

    const translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });

    const imageUrl = achievement.image ? `${process.env.REACT_APP_API_URL}/assets/${achievement.image}?key=achievement-image` : TrophyImage;

    const achievements = [
        {
            title: translatedAchievement.title || '',
            imageUrl,
            completed: achievement. pointsAlreadyAwardedForAchievement + 1,
            target: achievement.target,
            description: translatedAchievement.description,
            successMessage: translatedAchievement.success_message,
        },
    ];

    const handleClose = (close = false) => {
        let modalToOpen = null;

        // if user has not entered contest, then open contest form
        if (enteredContentStatus === 'not entered') {
            modalToOpen = 'contest-info';
        }

        if (isThereAnotherAchievement === 'yes') {
            if (achievement.modalToOpen) {
                modalToOpen = achievement.modalToOpen;
            }
        } else {
            modalToOpen = 'promotion';
        }

        // if close is true, then close the modal
        if (close === true) {
            modalToOpen = 'promotion';
        }
        
        onClose(modalToOpen);
    };

    const handleOnClose = (close = false) => {
        let modalToOpen = nextAchievement?.modalToOpen;

        // if user has completed all achievements, then open promotion
        if (completedAchievementsCount === 3) {
            modalToOpen = 'promotion';
        }

        // if close is true, then close the modal
        if (close === true) {
            modalToOpen = 'promotion';
        }
        
        onClose(modalToOpen);
    };

    useEffect(async () => {
        getContestJunctionItems();

        let nextAchievementId = null;
        let completed = 0;

        // check if each of the achievements have been completed
        for (let i = 0; i < orderedAchievementsList.length; i++) {
            const achievementItem = orderedAchievementsList[i];
            
            // skip if current achievement item is the same as the achievement prop
            if (achievementItem.id === achievement.id) {
                completed += 1;
                continue;
            }

            const achievements = await fetchAchievements(achievementItem.id);

            // if achievement has not been completed, then set it as next achievement
            if (!achievements?.length) {
                // if nextAchievementId is null, then set it to the current achievementId
                if (!nextAchievementId) {
                    nextAchievementId = achievementItem;
                }
            } else {
                completed += 1;
            }
        }

        setNextAchievement(nextAchievementId);
        setCompletedAchievementsCount(completed);
    }, [achievement]);

    return (
        <Dialog
            className='page-header'
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={() => handleClose(true)}
            TransitionComponent={Transition}
            scroll="paper"
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle
            sx={{
                padding: '0 20px',
                height: '3em',
                marginTop: '1rem',
            }}>
            <Box
                    sx={{}}
                >
                    <Box>
                        <Grid container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid item
                                xs={8}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            s: '1.5rem',
                                            md: '1.5rem',
                                        },
                                        fontWeight: 'bolder',
                                        '& strong': {
                                            color: '#B92534',
                                        },
                                        lineHeight: '1'
                                    }}
                                >
                                    <Wysiwyg>
                                        {t('enterContestTitle')}
                                    </Wysiwyg>
                                </Typography>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    height: '3rem',
                                }}
                            >
                                <img
                                    className=""
                                    src={CanadaBeefLogo}
                                    alt={'wheel'}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <IconButton
                    onClick={() => handleClose('promotion')}
                    sx={{
                        color: '#000',
                        position: 'absolute',
                        top: '0.15rem',
                        right: '0.25rem',
                        zIndex: '1',
                    }}
                >
                    <Close fontSize="interit" />
                </IconButton>
            </DialogTitle>

            <DialogContent
                sx={{}}
            >
                <Box
                    sx={{
                        textAlign: 'right',
                        fontSize: '0.6rem'
                    }}
                >
                    Entries {completedAchievementsCount}/3
                </Box>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                        background: "#eeeeee",
                        mb: '0.5rem',
                    }}
                >
                    <Box
                        sx={{
                            height: {
                                xs: 'auto',
                            },
                            width: `${Math.floor((completedAchievementsCount / 3) * 100)}%`,
                            background: "#004466",
                            clear: "none",
                            zIndex:"-1",
                            position: "relative-layer",
                            textAlign: 'right',
                            color: '#ffffff',
                        }}
                    >
                        {Math.floor((completedAchievementsCount / 3) * 100)}% &nbsp;
                    </Box>
                </Box>

                <Typography
                    className='title'
                    variant="h2"
                    sx={{
                        color: '#000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        mt: 1,
                        lineHeight: '1.25rem',
                        mb: '1rem'
                    }}
                >
                    {completedAchievementsCount === 1 && <Wysiwyg>{t('contestEnter')}</Wysiwyg>}
                    {completedAchievementsCount === 2 && <Wysiwyg>{t('contestGainedAnotherEntry')}</Wysiwyg>}
                    {completedAchievementsCount === 3 && <Wysiwyg>{t('completedAllAchievementsContent')}</Wysiwyg>}
                </Typography>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        width: '100%',
                    }}
                >
                    <img
                        src={ContestArt}
                        alt={'Contest Art'}
                        style={{
                            height: '150px',
                            width: '100%',
                            objectFit: 'cover',
                            objectPosition: 'bottom',
                        }}
                    />
                </Box>
                
                {
                    !!completedAchievementsCount &&
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '0',
                            backgroundColor: '#008F01',
                            fontFamily: retailer.button_font,
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            width: '100%',
                            mb: 2,
                        }}
                        onClick={handleOnClose}
                    >
                        {completedAchievementsCount === 1 && t('thirdChanceToWin')}
                        {completedAchievementsCount === 2 && t('thirdChanceToWin')}
                        {completedAchievementsCount === 3 && t('completedAllAchievements')}
                    </Button>
                }

            </DialogContent>
        </Dialog>
    )
}

const DialogAward = ({ defaultOpen = false, onClose }) => {
    const { achievement } = useContext(AwardsContext);

    const handleClose = (component) => {
        if (onClose) {
            onClose(component);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <AwardDialogoxBox
                achievement={achievement}
                open={defaultOpen}
                onClose={handleClose}
                key={defaultOpen}
            />
        </Box>
    )
}

export default DialogAward;