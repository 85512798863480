import { forwardRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, Slide, Stack, Typography } from "@mui/material";

import RetailerContext from "../../../context";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';
import ContestArt from '../../../shared/img/Bitmap.png';

import Wysiwyg from "../../Wysiwyg";
import api from "../../../api";
import { useGetShortCode } from "../../../hooks/useGetShortCode";
import notify from "../../Notification/helper";

import styles from './styles.module.scss';
import { DialogContestFooter } from "../DialogContestFooter";
import { DialogContestTitle } from "../DialogContestTitle";
import { fetchShortCode } from "../../../utils/shortCode";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            {...props}
        />
    );
});

const ContestFormDialogBox = ({ open, onClose }) => {
    const { t } = useTranslation(['gamification', 'provinces']);
    const { retailer } = useContext(RetailerContext);
    let shortCode = useGetShortCode();

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [verifyEmail, setVerifyEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [province, setProvince] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [oldEnough, setOldEnough] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [receiveUpdates, setReceiveUpdates] = useState(false);

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    const onSubmit = async () => {
        let postShortCodeDetailsError = '';
        let postEnterContestError = '';

        if (!shortCode) {
            shortCode = await fetchShortCode();
        }

        try {
            setLoading(true);

            const userDetailsPayload = {
                name,
                surname,
                email,
                province,
                receive_updates: receiveUpdates,
                agreeToTerms,
            };

            const enterContestPayload = {
                contests_id: process.env.REACT_APP_CONTEST_ID,
                short_codes_id: shortCode,
            };

            postShortCodeDetailsError = await api.postShortCodeDetails(shortCode, userDetailsPayload);
            postEnterContestError = await api.enterContest(enterContestPayload);
            handleClose('contest-form-success');
        } catch(error) {
            error?.map(err => {
                const errorMessage = {
                    message: `${err?.message}`,
                    postShortCodeDetailsError,
                    postEnterContestError,
                    shortCode
                };

                notify('error', JSON.stringify(errorMessage));
            });
        } finally {
            setLoading(false);
        }
    }

    const readyToSubmit = () => {
        return (
            name &&
            surname &&
            email && email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) &&
            email === verifyEmail &&
            province &&
            agreeToTerms &&
            oldEnough
        )
    };

    const getLegalAgeLabel = (province) => {
        const provincesWith18AsLegalAge = [
            'AB',
            'MB',
            'ON',
            'PE',
            'QC',
            'SK',
        ];

        if (provincesWith18AsLegalAge.includes(province)) {
            return t('legalAge18');
        } else {
            return t('legalAge19');
        }
    }

    useEffect(async () => {
        try {
            // fetch list of configured province settings
            const provincesResponse = await api.getListProvinces({
                filter: {}
            });

            const formattedProvinces = provincesResponse.map(province => (
                {
                    name: province.name,
                }
            ));

            setProvinces(formattedProvinces);
        } catch (error) {
            error?.map(err => notify('error', err.message));
        }
    }, []);

    return (
        <Dialog
            className={`page-header ${styles.contest}`}
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            scroll={'paper'}
            onClose={() => handleClose('promotion')}
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent>
                <Typography
                    variant="h2"
                    sx={{
                        color: '#000000',
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        my: 2,

                    }}
                >
                    <Wysiwyg>
                        {t('enterContestSubtitle')}
                    </Wysiwyg>
                </Typography>

                <Box>
                    <Typography
                        component={'div'}
                        sx={{
                            color: '#000',
                            fontWeight: 300,
                            fontSize: '0.8rem',
                            mb: 1,
                            textTransform: 'capitalize',
                        }}
                    >
                        <Wysiwyg>
                            {t('enterContestInstructions')}
                        </Wysiwyg>
                    </Typography>

                    <Stack
                        spacing={1}
                        sx={{
                            my: 0,
                        }}
                    >
                        <Stack
                            sx={{
                                my: 0,
                                flexGrow: 1,
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('name').toLocaleUpperCase()} *`}
                                    value={name}
                                    onChange={ (e) => setName(e.target.value) }
                                    variant="filled"
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('surname').toLocaleUpperCase() } *`}
                                    value={surname}
                                    onChange={ (e) => setSurname(e.target.value) }
                                    variant="filled"
                                />
                            </FormControl>
                            
                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('email').toLocaleUpperCase()} *`}
                                    value={email}
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="filled"
                                />

                                {
                                    !!email && !email.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailError')}
                                    </Typography>
                                }
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <OutlinedInput
                                    className="contest-input-field"
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                    placeholder={`${t('verifyEmail').toLocaleUpperCase()} *`}
                                    value={verifyEmail}
                                    type="email"
                                    onChange={(e) => setVerifyEmail(e.target.value)}
                                    variant="filled"
                                />

                                {
                                    !!verifyEmail && !verifyEmail.match(
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    ) &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailError')}
                                    </Typography>
                                }

                                {
                                    !!email && verifyEmail !== email &&
                                    <Typography
                                        sx={{
                                            color: '#B92534',
                                            fontSize: '0.8rem',
                                            fontWeight: 'bold',
                                            textAlign: 'left',
                                        }}
                                    >
                                        {t('validationEmailNotMatchError')}
                                    </Typography>
                                }
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                }}
                            >
                                <Select
                                    className="contest-input-field"
                                    value={province}
                                    onChange={(event) => setProvince(event.target.value)}
                                    displayEmpty
                                    sx={{
                                        color: '#262626 !important',
                                        height: '2rem',
                                        py: '0.2em',
                                        width: '100%',
                                        background: '#fbfbfb'
                                    }}
                                >
                                    <MenuItem value="">
                                        <Typography
                                            variant="span"
                                            sx={{
                                                color: '#adadad',
                                            }}
                                        >
                                            {`${t('province').toLocaleUpperCase()} *`}
                                        </Typography>
                                    </MenuItem>

                                    {
                                        provinces.map(province => (
                                            <MenuItem
                                                key={province.name}
                                                value={province.name}
                                            >
                                                {t(province.name, { ns: 'provinces' })}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                            {
                                !!province &&
                                <FormControl
                                    variant="outlined"
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={oldEnough}
                                                onChange={(event) => setOldEnough(event.target.checked)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    lineHeight: '0.9rem'
                                                }}
                                            >
                                                <Wysiwyg>
                                                    {`${getLegalAgeLabel(province)}<strong>&nbsp;*</strong>`}
                                                </Wysiwyg>
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            }
                            
                            <FormControl
                                variant="outlined"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreeToTerms}
                                            onChange={(event) => setAgreeToTerms(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            <Wysiwyg>
                                            {`${t('agreeToTerms')}*`}
                                            </Wysiwyg>
                                           
                                        </Typography>
                                    }
                                />
                            </FormControl>

                            <FormControl
                                variant="outlined"
                                sx={{
                                    mb: 3,
                                    mt: 2
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={receiveUpdates}
                                            onChange={(event) => setReceiveUpdates(event.target.checked)}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '0.8rem',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            {t('receiveUpdates')}
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </Box>

                <Typography
                    sx={{
                        color: '#000',
                        mb: 1.5,
                        fontStyle: 'italic',
                        fontSize: '0.8rem',
                        lineHeight: '0.9rem'
                    }}
                >
                    * {t('requiredFields')}

                </Typography>

                
                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            sx={{
                                borderRadius: '0',
                                backgroundColor: '#008F01',
                                fontFamily: retailer.button_font,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                width: '100%',
                                mb: 1,
                            }}
                            onClick={onSubmit}
                            disabled={!readyToSubmit() || loading}
                        >
                            {t('enterContest')}
                        </Button>
                        <Typography
                    sx={{
                        color: '#000',
                        mb: 1.5,
                        fontStyle: 'italic',
                        fontSize: '0.8rem',
                        lineHeight: '0.9rem'
                    }}
                >
                    {t('optDisclaimer')}

                </Typography>
                        <DialogContestFooter
                            rules={true}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
                
            </DialogContent>
        </Dialog>
    )
}

export const DialogContestForm = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box className='no-print'>
            <ContestFormDialogBox
                open={defaultOpen}
                onClose={handleClose}
            />
        </Box>
    )
};