import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Stack, Typography } from '@mui/material';

import moment from 'moment';

import BackgroundImage from '../../shared/img/video-thumbnail.jpg';

import RetailerContext from '../../context';

import { getTranslation, translateDifficulty } from '../../utils/translate';

import Wysiwyg from '../Wysiwyg';

import './index.scss';

const RecipeTile = ({ recipe = {}, imageTransformation = 'tile-background-image' }) => {
    const { t } = useTranslation();
    const language = useSelector(state => state.language);

    const retailerContext = useContext(RetailerContext);

    const location = useLocation();

    const placeholderImage = retailerContext.retailer.placeholder_image ?
        `${process.env.REACT_APP_API_URL}/assets/${retailerContext.retailer.placeholder_image}?key=${imageTransformation}`
    : BackgroundImage;

    const recipeImage = recipe?.imagegallery?.length ? 
        `${process.env.REACT_APP_API_URL}/assets/${recipe.imagegallery[0].directus_files_id}?key=${imageTransformation}` :
        placeholderImage;

    const renderTime = (condition) => {
        if (condition < 60) {
        return moment().startOf('day').add(condition, 'minutes').format('mm[min]');
        }

        if (!(condition % 60)) {
        return moment().startOf('day').add(condition, 'minutes').format('H[hr]');
        }

        return moment().startOf('day').add(condition, 'minutes').format('H[hr] mm[min]');
    };

    return (
    <>
        <Box
            sx={{
                position: 'relative'
            }}
        >
            <Link
                to={{
                    pathname: `/recipe/${recipe.id}`,
                    state: {
                        recipe,
                        from: `${location.pathname}${location.search}`
                    }
                }}
                style={{ textDecoration: 'none' }}
                className="w-100"
            >
                <Stack
                    direction="column"
                    justifyContent="flex-end"
                    className='tile_background'
                    sx={{
                        py: {
                            xs: 2,
                            md: 3,
                            lg: 3,
                        },
                        px: {
                            xs: 2,
                            md: 3,
                            lg: 3,
                        },
                        color: '#ffffff',
                        border: '1px solid #000',
                        height: '330px',
                        background: `linear-gradient(to bottom, RGBA(0, 0, 0, 0.55), RGBA(0, 0, 0, 0) 57%, RGBA(0, 0, 0, 0.77)), url(${recipeImage}) no-repeat center center`,
                        backgroundBlendMode: "multiply",
                        position: 'relative',
                        boxShadow: '-2px 10px 20px rgba(0,0,0,0.6) inset'
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        justifyContent="flex-end"
                        sx={{
                            pr: 1,
                            ml: 'auto',
                            width: 'fit-content'
                        }}
                    >
                        <Grid item xs={6}>
                            <Stack
                                direction='column'
                                alignItems='flex-end'
                            >
                                <Typography variant="h6">
                                    <strong>
                                        {getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations').ingredients_list?.length || 0}
                                    </strong>
                                </Typography>
                                <Typography variant="h6">{t('ingredients')}</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack
                                direction='column'
                                alignItems='flex-end'
                            >
                                <Typography variant="h6">
                                    <strong>{recipe?.yields || 0}</strong>
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        textTransform: 'lowercase'
                                    }}
                                >
                                    {t('portions')}
                                </Typography>
                            </Stack>
                        </Grid>

                    </Grid>
                </Stack>
            </Link>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 9,
                    mt: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                    mx: {
                        xs: 2,
                        md: 3,
                        lg: 3,
                    },
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        my: 0,
                        color: '#ffffff',
                        fontSize: '32px',
                        fontWeight: '500'
                    }}
                >
                    <Wysiwyg>
                        {getTranslation(recipe, { key: 'languages_code', code: language.code }, 'layout_translations').name}
                    </Wysiwyg>
                </Typography>
            </Box>
        </Box>
    </>
    )
};

export default RecipeTile;