import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";

import CanadaBeefLogo from '../../../shared/svg/CanadaBeefLogo.svg';

import Wysiwyg from "../../Wysiwyg";

export const DialogContestTitle = () => {
    const { t } = useTranslation(['gamification', 'provinces']);

    return (
        <Box
                    sx={{}}
                >
                    <Box>
                        <Grid container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Grid item
                                xs={8}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        fontSize: {
                                            xs: '1rem',
                                            s: '1.5rem',
                                            md: '1.5rem',
                                        },
                                        fontWeight: 'bolder',
                                        '& strong': {
                                            color: '#B92534',
                                        },
                                        lineHeight: '1'
                                    }}
                                >
                                    <Wysiwyg>
                                        {t('enterContestTitle')}
                                    </Wysiwyg>
                                </Typography>
                            </Grid>
                            <Grid
                                xs={4}
                                sx={{
                                    height: '3rem',
                                }}
                            >
                                <img
                                    className=""
                                    src={CanadaBeefLogo}
                                    alt={'wheel'}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
    )
}