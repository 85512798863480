import userSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { fetcher } from '../utils/fetcher';
import { useContext } from 'react';
import RetailerContext from '../context';
import { useGetShortCode } from './useGetShortCode';
export const useFetchAchievements = () => {
    const retailerContext = useContext(RetailerContext);
    const shortCode = useGetShortCode();

    const fields = [
        '*',
        'translations.*',
        'points.*',
    ];

    const deep = {
        points: {
            _filter: {
                short_code: {
                    _eq: shortCode
                }
            }
        },
    };

    const filter = {
        _and: [
            {
                status: {
                    '_eq': 'published'
                }
            },
            {
                retailers: {
                    retailer_id: {
                        '_eq': retailerContext.retailer.id
                    }
                }
            }
        ]
    };

    const { data: defaultData, error, isLoading } = userSWR({
        url: 'achievements',
        deep,
        fields,
        filter,
        sort: '-date_created'
    }, fetcher);

    const { trigger, data } = useSWRMutation({
        url: 'achievements',
        deep,
        fields,
        filter,
        sort: '-date_created'
    }, fetcher)

    const achievements = data ? data?.map(achievement => {
        return {
            ...achievement,
            points: achievement.points.reduce((accumulator, current) => {
                return accumulator + current.points;
            }, 0),
        }
    }) : defaultData?.map(achievement => {
        return {
            ...achievement,
            points: achievement.points.reduce((accumulator, current) => {
                return accumulator + current.points;
            }, 0),
        }
    });

    return {
        data: achievements,
        trigger,
        error,
        isLoading,
    };
};