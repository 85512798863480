import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";

import RetailerContext from "../../../context";

import { AwardsContext } from "../../../context/AwardsContext";

const DialogShare = () => {
    const { t } = useTranslation(['gamification']);

    const { retailer } = useContext(RetailerContext);
    const { setModalToOpen } = useContext(AwardsContext);

    const handleClick = (componentToOpen = null) => {
        setModalToOpen(componentToOpen);
    }


    return (
        <Box
            className='no-print'
        >
            {/*<Button
                sx={{
                    width: '100%',
                    borderRadius: '0',
                    backgroundColor: '#008F01',
                    fontFamily: retailer.button_font,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    ':hover': {
                        bgcolor: retailer.button_background_color,
                    },
                }}
                variant="contained"
                onClick={() => { handleClick('social-share') }}
            >
                {t('shareYourProgress')}
            </Button>*/
}
        </Box>
    )
}

export default DialogShare;