import { forwardRef, useContext, useState } from "react";
import { useSelector } from "react-redux";


import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from "@mui/material";

import ContestArt from '../../../shared/img/Bitmap.png';

import { getTranslation } from "../../../utils/translate";

import Wysiwyg from "../../Wysiwyg";
import VideoWatched from "../../VideoWatched";
import { DialogContestTitle } from "../DialogContestTitle";
import { DialogContestFooter } from "../DialogContestFooter";
import RetailerContext from "../../../context";
import { useTranslation } from "react-i18next";
import { useFetchAchievement } from "../../../hooks/useFetchAchievement";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            unmountOnExit
            {...props}
        />
    );
});

const WatchVideoDialogBox = (props) => {
    const { open = false, onClose, title, description, videoUrl, content, achievementId, collection, id } = props;

    const { code: languageCode } = useSelector(state => state.language);

    const { data } = useFetchAchievement(achievementId);

    const achievement = data?.[0] || {};
    const translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });
    const achievementVideoUrl = translatedAchievement.video ? `${process.env.REACT_APP_API_URL}/assets/${translatedAchievement.video}` : null;

    const [playStatus, setPlayStatus] = useState('idle');

    const handleClose = (componentToOpen = null) => {
        onClose(componentToOpen);
    };

    return (
        <Dialog
            className='page-header'
            open={open}
            onClose={() => handleClose('promotion')}
            fullWidth={true}
            TransitionComponent={Transition}
            maxWidth={'xs'}
            scroll="paper"
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                }
            }}
        >
            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent
                sx={{
                    overflowX: 'hidden',
                }}
            >
                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 1,
                        mx: '-1.5rem',
                        px: '1.5rem',
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '1.5rem',
                            lineHeight: '1.25rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: '#000',
                            mb: 1,
                        }}
                    >
                        {translatedAchievement.title || title}
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1.1rem',
                            fontWeight: '400',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            color: '#000000',
                            mb: '1.25rem'
                        }}
                    >
                        {description}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 2,
                        mx: '-1.5rem',
                    }}
                >
                    {
                        playStatus === 'played' &&
                        <VideoWatched
                            played={playStatus === 'played'}
                            onEnded={() => setPlayStatus('played')}
                            achievementId={achievementId}
                            collection={collection}
                            id={id}
                        />
                    }
                    <Box
                        className="recipe_video"
                        sx={{
                            width: "100%",
                            height: '210px',
                            background: "#000",
                            alignContent: "center",
                            mb: 2,
                        }}
                    >
                        <video
                            className="video-list_img"
                            controls
                            onEnded={() => setPlayStatus('played')}
                        >
                            <source
                                src={achievementVideoUrl}
                                type="video/mp4"
                            />
                        </video>
                    </Box>
                </Box>

                <Box
                    sx={{
                        textAlign: 'justify',
                        my: 1,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '0.8rem',
                            lineHeight: '1em'
                        }}
                    >
                        <Wysiwyg>
                            {content}
                        </Wysiwyg>
                    </Typography>
                </Box>

                <Stack>
                    <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <DialogContestFooter
                            rules={false}
                            handleClose={() => handleClose('promotion')}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}
export const DialogWatchVideo = ({ defaultOpen = false, onClose, title, description, videoUrl, content, achievementId, collection, id }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <WatchVideoDialogBox
                open={defaultOpen}
                onClose={handleClose}
                key={defaultOpen}
                title={title}
                description={description}
                videoUrl={videoUrl}
                content={content}
                achievementId={achievementId}
                collection={collection}
                id={id}
            />
        </Box>
    )
}